import {
  Button,
  FilterPopover,
  InsightsIcon,
  useStyletron,
  Input,
  Wrapper,
  SearchIcon,
  Tokens,
  RefreshIcon,
} from '@visualfabriq/vf-ui-kit';

import { PipelineListItem } from 'src/api-new/bifrost';
import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { PipelineTableFilters } from '../../types';
import { PIPELINE_STATUSES } from 'src/domain/pipelines/constants';
import { Link } from 'react-router-dom';
import { pathConstants } from 'src/routes';

type Props = {
  pipelinesList: PipelineListItem[] | undefined;
  onNewPipeline: () => void;
  onCreateUsingTemplate: () => void;
  onRefresh: () => void;
  filters: PipelineTableFilters;
  onFiltersChange: (filters: PipelineTableFilters) => void;
  onNameQueryChanged: (query: string) => void;
};

export function PipelinesActions(props: Props) {
  const { pipelinesList, onNewPipeline, onCreateUsingTemplate, onRefresh, onFiltersChange, onNameQueryChanged } = props;
  const [css] = useStyletron();
  const userPermission = useUserPermissions();
  const canCreatePipeline = userPermission.has('PipelinesCreate');
  const [_, theme] = useStyletron();

  return (
    <Wrapper direction="column" gap={200}>
      <Wrapper gap={Tokens.ref.sizing.scale200}>
        <Button disabled={!canCreatePipeline} onClick={onNewPipeline}>
          Create single Pipeline
        </Button>
        <Button disabled={!canCreatePipeline} onClick={onCreateUsingTemplate}>
          Pipeline module wizard
        </Button>
        <Link
          className={css({ marginLeft: 'auto' })}
          to={`../${pathConstants.DASHBOARD.root}/${pathConstants.DASHBOARD.pipelineSchedule}`}
        >
          <Button startEnhancer={<InsightsIcon color={theme.colors.accent} />} kind="tertiary">
            Schedule Dashboard
          </Button>
        </Link>
      </Wrapper>
      <Wrapper justifyContent="space-between">
        <Input
          overrides={{ Root: { style: { width: 'auto', flex: '3 0 auto' } } }}
          startEnhancer={() => <SearchIcon />}
          placeholder="Search by name..."
          onChange={(event) => onNameQueryChanged(event.target.value)}
          clearable
        />
        <Wrapper justifyContent="end" className={css({ flex: '2 0 auto' })} gap={Tokens.ref.sizing.scale200}>
          {pipelinesList && (
            <FilterPopover
              onApply={(event) => onFiltersChange(event as PipelineTableFilters)}
              filterItems={[
                { key: 'step_name', dimension: 'Step Name', filter_type: 'text', conditions: ['contains'] },
                {
                  key: 'id',
                  dimension: 'Pipeline',
                  filter_type: 'select',
                  conditions: ['nin', 'in'],
                  value_list: pipelinesList.map((p) => ({ id: p.id, name: p.name })),
                },
                {
                  key: 'enabled',
                  dimension: 'Pipeline Status',
                  filter_type: 'select',
                  conditions: ['exact'],
                  value_list: PIPELINE_STATUSES.map((status) => ({ id: status, name: status })),
                },
              ]}
              itemIdKey="key"
            />
          )}
          <Button endEnhancer={RefreshIcon} onClick={onRefresh}>
            Refresh
          </Button>
        </Wrapper>
      </Wrapper>
      <div style={{ marginLeft: 'auto' }}>
        <Wrapper gap={200}></Wrapper>
      </div>
    </Wrapper>
  );
}

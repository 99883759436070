import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { Button, ContentWrapper, Select, Wrapper } from '@visualfabriq/vf-ui-kit';

import {
  DataStagingConfigInputType,
  PipelineTemplate,
  PipelineTemplateListItem,
  PipelineTemplatesApi,
  VfModulesApi,
} from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { FilledConfig } from 'src/domain/pipelines/types';
import { OnChangeFn, OnValidateChangeFn, Template } from '../Template';
import { TemplateSearch } from './TemplateSearch';

export function UsingTemplate({
  onCreate,
}: {
  onCreate: (args: {
    template: PipelineTemplate;
    filledConfig: FilledConfig;
    name: string | undefined;
  }) => Promise<void>;
}) {
  const vfModulesApi = useBifrostApi(VfModulesApi);
  const templatesApi = useBifrostApi(PipelineTemplatesApi);
  const [selectedModuleId, setSelectedModuleId] = useState<string>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const [validationState, setValidationState] = useState<ValidationState | undefined>(undefined);
  const [filledConfig, setFilledConfig] = useState<FilledConfig>({});
  const [name, setName] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const modules = useAsync(async () => {
    const { data: modules } = await vfModulesApi.getVfModules();
    modules.sort((module1, module2) => module1.name.localeCompare(module2.name));
    return modules;
  }, []);

  const templates = useAsync(async () => {
    if (!selectedModuleId) {
      setSelectedTemplateId(undefined);
      return null;
    }

    const { data: templates } = await templatesApi.getPipelineTemplates({ moduleId: [selectedModuleId] });
    return templates;
  }, [selectedModuleId]);

  const template = selectedTemplateId ? templates.value?.find((t) => t.id === selectedTemplateId) : undefined;

  useEffect(() => {
    if (!template) {
      setValidationState(undefined);
      setFilledConfig({});
    } else {
      const newFilledConfig = template.steps_configuration.reduce<FilledConfig>((configMap, config) => {
        return {
          ...configMap,
          [config.id]:
            config.type === 'data_processing'
              ? config.args.reduce((args, arg) => ({ ...args, [arg.key]: arg.default_value ?? '' }), {})
              : {},
        };
      }, {});
      setFilledConfig(newFilledConfig);
      setValidationState(
        template.steps_configuration.reduce(
          (configMap, config) => ({
            ...configMap,
            [config.id]: [DataStagingConfigInputType.DataStaging].includes(config.type),
          }),
          {},
        ),
      );
    }
  }, [template]);

  const handleValidateChange: OnValidateChangeFn = ({ stepConfigurationId, isValid }) => {
    setValidationState((v) => ({ ...(v ?? {}), [stepConfigurationId]: isValid }));
  };

  const handleChange: OnChangeFn = ({ stepConfigurationId, args }) => {
    setFilledConfig({
      ...filledConfig,
      [stepConfigurationId]: args,
    });
  };

  const handleSearchSelect = (template: PipelineTemplateListItem) => {
    setSelectedModuleId(template.vf_module_id);
    setSelectedTemplateId(template.id);
  };

  const handleCreate = async () => {
    try {
      setIsLoading(true);
      await onCreate({ template: template!, filledConfig, name });
    } finally {
      setIsLoading(false);
    }
  };

  const isValid = validationState ? Array.from(Object.values(validationState)).every(Boolean) : false;

  return (
    <Wrapper gap={200} direction="column">
      <Wrapper gap={400} direction="column">
        <TemplateSearch onSelect={handleSearchSelect} />
        <Wrapper gap={200}>
          <Select
            isLoading={modules.loading}
            value={selectedModuleId ? [{ id: selectedModuleId }] : []}
            options={modules.value ? modules.value.map((m) => ({ id: m.id, label: m.name })) : []}
            onChange={(event) => {
              if (event.type !== 'select') return;
              //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
              setSelectedModuleId(event.value[0].id as string);
            }}
            placeholder="Select module.."
          />
          <Select
            disabled={!selectedModuleId || !templates.value}
            isLoading={templates.loading}
            value={selectedTemplateId ? [{ id: selectedTemplateId }] : []}
            options={templates.value ? templates.value.map((m) => ({ id: m.id, label: m.label })) : []}
            onChange={(event) => {
              if (event.type !== 'select') return;
              //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
              setSelectedTemplateId(event.value[0].id as string);
            }}
            placeholder="Select template.."
          />
          <Button disabled={!isValid} onClick={handleCreate} isLoading={isLoading}>
            Create Using Template
          </Button>
        </Wrapper>
      </Wrapper>
      {template && (
        <ContentWrapper>
          <Template
            name={name}
            onNameChange={setName}
            template={template}
            filledConfigMap={{ [template.id]: filledConfig }}
            onValidateChange={handleValidateChange}
            onChange={handleChange}
          />
        </ContentWrapper>
      )}
    </Wrapper>
  );
}

type ValidationState = Record<string, boolean>;

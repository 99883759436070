import {
  Pagination,
  Tokens,
  useSnackbar,
  Wrapper,
  Select,
  type Value,
  useStyletron,
  Skeleton,
} from '@visualfabriq/vf-ui-kit';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useAsyncRetry } from 'react-use';

import { PipelineFilteredRequestBody, PipelinesApi, PipelineStepsApi } from 'src/api-new/bifrost';
import { useDebouncedValue } from 'src/components/hooks/useDebouncedValue';
import { useInstances } from 'src/components/hooks/useInstances';
import { useDrawer } from 'src/components/molecules/Drawer/useDrawer';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { getOrderedPipelineSteps } from 'src/domain/pipelines/getOrderedPipelineSteps';
import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import { useBifrostApi } from 'src/services/useBifrostApi';

import { AddPipelineModal } from './components/AddPipelineModal';
import { CrateUsingTemplateDrawer } from './components/CrateUsingTemplate';
import { NoPipelines } from './components/NoPipelines';
import { PipelinesActions } from './components/PipelinesActions';
import { PipelinesTable } from './components/PipelinesTable';

import { captureException } from 'src/services/sentry';
import { CreateFromTemplatesErrors } from './components/CreateFromTemplatesErrors';
import { ExportedPipeline, PipelineTableFilters } from './types';
import { formatFilter } from './utils';

const PIPELINE_AMOUNT_DEFAULT = 10;

type ShowProps<T> = {
  when: T | undefined | null | false;
  fallback?: ReactNode;
  children: ReactNode | ((item: T) => ReactNode);
};

function Show<T>({ when, fallback = null, children }: ShowProps<T>): JSX.Element | null {
  if (!when) {
    return fallback ? <>{fallback}</> : null;
  }

  if (typeof children === 'function') {
    const renderedChildren = children(when);
    return <>{renderedChildren}</>;
  }

  return <>{children}</>;
}

export const Pipelines = () => {
  const { pipelines, pagination, loading, api: pipelineApi } = useContext(PipelinesContext);
  const [css, theme] = useStyletron();
  const { selectedInstance } = useInstances();
  const { openModal, closeModal } = useContext(ModalContext);
  const { openDrawer, closeDrawer } = useDrawer();
  const snackbar = useSnackbar();
  const [sortColumn, setSortColumn] = useState('name');
  const [sortAsc, setSortAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<PipelineTableFilters>([]);
  const [nameQuery, setNameQuery] = useState('');
  const { value: debouncedNameQuery } = useDebouncedValue(nameQuery, 250);
  const pipelinesApi = useBifrostApi(PipelinesApi);
  const pipelineStepsApi = useBifrostApi(PipelineStepsApi);
  const [pageSize, setPageSize] = useState<Value>([
    { id: String(PIPELINE_AMOUNT_DEFAULT), label: String(PIPELINE_AMOUNT_DEFAULT) },
  ]);

  const pipelinesList = useAsyncRetry(async () => {
    const { data } = await pipelinesApi.getPipelinesList({ instanceId: selectedInstance.id });
    return data;
  }, [selectedInstance]);
  useEffect(() => pipelinesList.retry(), [pipelines]);

  useEffect(() => {
    if (selectedInstance.id) {
      fetchPipelines({
        instance_id: selectedInstance.id,
        current_page: currentPage,
        page_size: pageSize[0]?.id ? Number(pageSize[0]?.id) : PIPELINE_AMOUNT_DEFAULT,
        sort: sortColumn as 'name' | 'enabled' | 'description' | 'schedule',
        order: sortAsc ? 'asc' : 'desc',
        filters: [
          ...filters.map(formatFilter),
          { key: 'name', condition: 'contains', exact_value: debouncedNameQuery },
        ],
      });
    }
  }, [selectedInstance, sortColumn, sortAsc, currentPage, filters, debouncedNameQuery, pageSize]);

  function fetchPipelines(request: PipelineFilteredRequestBody) {
    try {
      pipelineApi.fetchPipelines(request);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      captureException(error);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      snackbar.enqueueErrorSnackbar(error.message);
    }
  }

  const createNewPipeline = async (event: {
    name: string;
    schedule: string;
    description: string;
    importedConfiguration: ExportedPipeline | null;
    timeZone: string;
  }) => {
    const { name, schedule, description, importedConfiguration, timeZone } = event;
    const createdPipeline = await pipelineApi.createPipeline({
      name,
      schedule,
      description,
      instanceId: selectedInstance.id,
      time_zone: timeZone,
    });

    if (importedConfiguration?.steps?.length && importedConfiguration.fsm?.transitions?.length) {
      const orderedSteps = getOrderedPipelineSteps(importedConfiguration.steps, importedConfiguration.fsm.transitions);

      let prevStepId: string | undefined = undefined;
      for await (const step of orderedSteps) {
        const { data: createdStep } = await pipelineStepsApi.createPipelineStep({
          pipelineStepCreate: {
            name: step.name ?? '(Empty)',
            description: step.description ?? '',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: step.type! as any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            configuration: step.configuration as any,
            pipeline_id: createdPipeline.id,
            previous_step_id: prevStepId,
          },
        });
        prevStepId = createdStep.id;
      }
    }
  };

  const handleAddNewPipeline = () => {
    openModal(
      <AddPipelineModal
        onCreateManually={async (event) => {
          try {
            snackbar.enqueueSuccessSnackbar('Creating pipeline...');
            await createNewPipeline(event);
            snackbar.enqueueSuccessSnackbar('Pipeline has been created');
          } catch (error) {
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            snackbar.enqueueErrorSnackbar(error.message);
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCreateUsingTemplate={async ({ template, filledConfig, name }) => {
          snackbar.enqueueSuccessSnackbar('Creating pipeline...');
          const { errorsMap } = await pipelineApi.createPipelinesFormTemplates({
            filledTemplateMap: { [template.id]: filledConfig },
            selectedTemplates: [template],
            instanceId: selectedInstance.id,
            nameByTemplateIdMap: { [template.id]: name },
          });
          if (errorsMap.size > 0) {
            snackbar.dequeue();
            errorsMap.forEach((templateError) => {
              captureException(templateError.error);
            });
            openModal(<CreateFromTemplatesErrors errorsMap={errorsMap} />, { closeable: true, size: 'auto' });
          } else {
            snackbar.enqueueSuccessSnackbar('Pipeline has been created');
            closeModal();
          }
        }}
      />,
      { size: 'auto', closeable: true },
    );
  };

  const handleCreateUsingTemplate = () => {
    openDrawer(
      <CrateUsingTemplateDrawer
        instanceId={selectedInstance.id}
        createPipelinesFormTemplates={pipelineApi.createPipelinesFormTemplates}
        onClose={closeDrawer}
      />,
      {
        size: 'auto',
        anchor: 'left',
        title: 'Pipeline module wizard',
      },
    );
  };

  const handleRefresh = () => {
    fetchPipelines({
      instance_id: selectedInstance.id,
      current_page: currentPage,
      page_size: pageSize[0]?.id ? Number(pageSize[0]?.id) : PIPELINE_AMOUNT_DEFAULT,
      sort: sortColumn as 'name' | 'enabled' | 'description' | 'schedule',
      order: sortAsc ? 'asc' : 'desc',
      filters: [...filters.map(formatFilter), { key: 'name', condition: 'contains', exact_value: debouncedNameQuery }],
    });
  };

  function handleSortChange(id: string) {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
    setCurrentPage(1);
  }

  const handleFilterChange = (filters: PipelineTableFilters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleNameQueryChange = (query: string) => {
    setNameQuery(query);
    setCurrentPage(1);
  };

  return (
    <Wrapper height="100%" width="100%" padding={`0 0 ${Tokens.ref.sizing.scale100}`} direction="column">
      <PipelinesActions
        pipelinesList={pipelinesList.value}
        filters={filters}
        onFiltersChange={handleFilterChange}
        onNewPipeline={handleAddNewPipeline}
        onCreateUsingTemplate={handleCreateUsingTemplate}
        onRefresh={handleRefresh}
        onNameQueryChanged={handleNameQueryChange}
      />
      {!loading && pipelines?.length === 0 ? (
        <NoPipelines
          filtered={filters.length > 0}
          onNewPipeline={handleAddNewPipeline}
          createUsingTemplate={handleCreateUsingTemplate}
        />
      ) : (
        <>
          <PipelinesTable
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            pipelines={pipelines as any}
            loading={loading}
            sortAsc={sortAsc}
            sortColumn={sortColumn}
            refresh={handleRefresh}
            handleSort={handleSortChange}
          />
          <Wrapper alignItems="center" margin={Tokens.ref.sizing.scale100} justifyContent="space-between">
            <Show when={!loading} fallback={<Skeleton width="100%" height="2rem" />}>
              <p className={css({ ...Tokens.sys.typography.LabelMedium, color: theme.colors.buttonDisabledText })}>
                Showing{' '}
                {`${(currentPage - 1) * Number(pageSize[0]?.id ?? PIPELINE_AMOUNT_DEFAULT) + 1} to ${Math.min(currentPage * Number(pageSize[0]?.id ?? PIPELINE_AMOUNT_DEFAULT), pagination.total_items!)} of ${pagination.total_items} results`}
              </p>
              <Wrapper gap={Tokens.ref.sizing.scale100}>
                <Select
                  getValueLabel={(value) => `Show ${value.option.label}`}
                  type="select"
                  className={css({})}
                  overrides={{
                    ValueContainer: {
                      style: { ...Tokens.sys.typography.LabelMedium },
                    },
                    Root: {
                      style: { flexGrow: 0, margin: 0, width: 'auto' },
                    },
                  }}
                  options={[
                    { id: '10', label: '10' },
                    { id: '25', label: '25' },
                    { id: '50', label: '50' },
                  ]}
                  value={pageSize}
                  onChange={({ value }) => {
                    setPageSize(value);
                    setCurrentPage(1);
                  }}
                />
                <Pagination
                  currentPage={currentPage}
                  numPages={pagination.total_pages}
                  onPageChange={({ nextPage }) => setCurrentPage(nextPage)}
                />
              </Wrapper>
            </Show>
          </Wrapper>
        </>
      )}
    </Wrapper>
  );
};

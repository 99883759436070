import { useState } from 'react';
import { Input, Button, FormLoadingBox, FormControl, Checkbox, Wrapper } from '@visualfabriq/vf-ui-kit';

import { FileFormItem } from 'src/components/organisms/FileFormItem';
import { useInstances } from 'src/components/hooks/useInstances';
//@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
import { getRelativePath } from 'src/domain/pipelines/getRelativePath';
import { SftpInboundRetrieverStep } from 'src/dto/PipelineStep';
import { StepConfigLayout } from '../StepConfigLayout';

type Props = {
  step: SftpInboundRetrieverStep;
  defaultFieldsConfiguration: JSX.Element;
  loading: boolean;
  onUpdate: (step: SftpInboundRetrieverStep) => void;
  deleteButton: JSX.Element;
};

export function SFTPInboundRetrieverStepConfig(props: Props) {
  const { step, onUpdate, defaultFieldsConfiguration, loading, deleteButton } = props;
  const { configuration } = step;

  const { selectedInstance } = useInstances();

  const [fileSearchExpression, setFileSearchExpression] = useState(configuration.file_search_expression ?? '');
  const [port, setPort] = useState(configuration?.connection_config?.port?.toString() ?? '');
  const [host, setHost] = useState(configuration?.connection_config?.host_name ?? '');
  const [username, setUsername] = useState(configuration?.connection_config?.username ?? '');
  const [destinationPath, setDestinationPath] = useState(
    configuration?.destination_path
      ? getRelativePath({ path: configuration?.destination_path, instance: selectedInstance })
      : '',
  );
  const [deleteAfterDownload, setDeleteAfterDownload] = useState(!!configuration.delete_after_download);
  const [password, setPassword] = useState(configuration?.connection_config?.password ?? '');
  const [pathToFile, setPathToFile] = useState(configuration?.path_to_file ?? '');
  const [privateKeyValue, setPrivateKeyValue] = useState<string | null>(
    configuration.connection_config.rsa_pkey ?? null,
  );

  const isValid = host && username && fileSearchExpression && destinationPath && (privateKeyValue || password);

  const handleUpdate = async () => {
    onUpdate({
      ...step,
      configuration: {
        ...step.configuration,
        path_to_file: pathToFile,
        file_search_expression: fileSearchExpression,
        destination_path: `${selectedInstance.name}/${destinationPath}`,
        delete_after_download: deleteAfterDownload,
        connection_config: {
          port: parseInt(port),
          host_name: host,
          rsa_pkey: privateKeyValue,
          username,
          password,
        },
      },
    });
  };

  return (
    <StepConfigLayout
      loader={<FormLoadingBox items={['text', 'text', 'text', 'text', 'text', 'text', 'buttonGroup']} itemsCount={7} />}
      loading={loading}
      footer={
        <Wrapper gap="200" direction="column">
          <Button disabled={!isValid} onClick={handleUpdate}>
            Update Step
          </Button>
          {deleteButton}
        </Wrapper>
      }
    >
      {defaultFieldsConfiguration}
      <FormControl label="File search expression" required>
        <Input value={fileSearchExpression} onChange={(event) => setFileSearchExpression(event.target.value)} />
      </FormControl>

      <FormControl label="Path to file">
        <Input value={pathToFile} onChange={(event) => setPathToFile(event.target.value)} />
      </FormControl>

      <FormControl label="Destination path" caption={`${selectedInstance.name}/${destinationPath}`} required>
        <Input value={destinationPath} onChange={(event) => setDestinationPath(event.target.value)} />
      </FormControl>

      <Checkbox
        checked={deleteAfterDownload}
        onChange={(event) => {
          setDeleteAfterDownload(event.target.checked);
        }}
      >
        Delete after download
      </Checkbox>

      <FormControl label="Port">
        <Input value={port} onChange={(event) => setPort(event.target.value)} />
      </FormControl>

      <FormControl label="Host" required>
        <Input value={host} onChange={(event) => setHost(event.target.value)} />
      </FormControl>

      <FormControl label="Username" required>
        <Input value={username} onChange={(event) => setUsername(event.target.value)} />
      </FormControl>

      <FormControl label="Password">
        <Input value={password} onChange={(event) => setPassword(event.target.value)} />
      </FormControl>

      <FileFormItem
        label="RSA Private Key"
        required={!password}
        onChange={setPrivateKeyValue}
        initialAttached={!!step.configuration.connection_config.rsa_pkey}
      />
    </StepConfigLayout>
  );
}

import { Button, FormControl, FormLoadingBox, Input, Select, Checkbox, Wrapper } from '@visualfabriq/vf-ui-kit';
import { useState } from 'react';

import * as DataProcessingStep from 'src/dto/PipelineStep/DataProcessingStep';
import { KeysValues } from 'src/components/molecules/KeysValues';
import { StepConfigLayout } from '../StepConfigLayout';
import { ECRImage } from 'src/api-new/bifrost';

type Props = {
  step: DataProcessingStep.DataProcessingStep;
  defaultFieldsConfiguration: JSX.Element;
  loading: boolean;
  onUpdate: (step: DataProcessingStep.DataProcessingStep) => void;
  deleteButton: JSX.Element;
};

export function DataProcessingStepConfig(props: Props) {
  const { step, onUpdate, defaultFieldsConfiguration, loading, deleteButton } = props;
  const configuration = step.configuration;

  const [image, setImage] = useState(configuration.ecr_image);
  const [job_size, setJobSize] = useState(configuration.job_size || DataProcessingStep.JobSize.FargateMicro);
  const [functionLink, setFunctionLink] = useState(configuration.function_link);
  const [jobTimeout, setJobTimeout] = useState(configuration.job_timeout);
  const [canBeStopped, setCanBeStopped] = useState(configuration.can_be_stopped ?? true);
  const [args, setArgs] = useState<[string, string, 'text' | 'number' | 'boolean' | 'dict' | 'list' | 'date'][]>(
    Object.entries(configuration.kwargs ?? {}).map(([key, value]) => [key, value.value, value.type]),
  );
  const [processingType, setProcessingType] = useState(
    configuration.processing_type || DataProcessingStep.ProcessingType.Managed,
  );
  const [scriptSignature, setScriptSignature] = useState(configuration.script_signature);

  const handleUpdate = () => {
    onUpdate({
      ...step,
      configuration: {
        ...step.configuration,
        ecr_image: image,
        job_size: job_size,
        function_link: functionLink,
        job_timeout: jobTimeout,
        can_be_stopped: canBeStopped,
        processing_type: processingType,
        script_signature: scriptSignature,
        kwargs: Object.fromEntries(
          args
            .filter(([key]) => key)
            .map(([key, value, type]) => [
              key,
              {
                value,
                type,
              },
            ]),
        ),
      },
    });
  };

  return (
    <StepConfigLayout
      loader={<FormLoadingBox items={['text', 'buttonGroup', 'block', 'double']} itemsCount={6} />}
      loading={loading}
      footer={
        <Wrapper gap="200" direction="column">
          <Button
            onClick={handleUpdate}
            disabled={
              (processingType === DataProcessingStep.ProcessingType.Custom && !scriptSignature && !image) ||
              (processingType === DataProcessingStep.ProcessingType.Managed && !image)
            }
          >
            Update
          </Button>
          {deleteButton}
        </Wrapper>
      }
    >
      {defaultFieldsConfiguration}

      <FormControl label="Image" required>
        <Select
          value={[{ id: image }]}
          options={Object.values(ECRImage).map((image) => ({ id: image, label: image }))}
          onChange={(event) => {
            if (event.type !== 'select') return;
            setImage(event.value[0]!.id as ECRImage);
          }}
        />
      </FormControl>

      <FormControl label="Job size" required>
        <Select
          value={[{ id: job_size }]}
          options={Object.values(DataProcessingStep.JobSize).map((job_size) => ({ id: job_size, label: job_size }))}
          onChange={(event) => {
            if (event.type !== 'select') return;
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            setJobSize(event.value[0].id as DataProcessingStep.JobSize);
          }}
        />
      </FormControl>

      <FormControl label="Processing Type" required>
        <Select
          value={[{ id: processingType }]}
          options={Object.values(DataProcessingStep.ProcessingType).map((processingType) => ({
            id: processingType,
            label: processingType,
          }))}
          onChange={(event) => {
            if (event.type !== 'select') return;
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            setProcessingType(event.value[0].id as DataProcessingStep.ProcessingType);
          }}
        />
      </FormControl>

      <FormControl
        label="Script"
        caption={
          processingType === DataProcessingStep.ProcessingType.Custom
            ? 'ScriptPath eg. scripts/my_script.py (data-processing-custom-scripts)'
            : 'ScriptLink eg. vf_standard_loads.nielsen.load.load'
        }
        required
      >
        <Input
          value={functionLink}
          onChange={(event) => {
            setFunctionLink(event.target.value);
          }}
        />
      </FormControl>

      <FormControl label="Script Signature" required>
        <Input
          type="text"
          value={scriptSignature}
          placeholder="Signature of the script"
          onChange={(event) => {
            setScriptSignature(event.target.value);
          }}
          disabled={processingType !== DataProcessingStep.ProcessingType.Custom}
        />
      </FormControl>

      <FormControl label="Timeout">
        <Input
          type="number"
          value={jobTimeout}
          placeholder="Timeout defined in seconds, default is 3600"
          onChange={(event) => {
            setJobTimeout(event.target.value as unknown as number);
          }}
        />
      </FormControl>

      <Checkbox
        checked={canBeStopped}
        onChange={(event) => {
          setCanBeStopped(event.target.checked);
        }}
      >
        Can be stopped?
      </Checkbox>

      <FormControl label="Arguments">
        <KeysValues
          value={args}
          withTypes={true}
          onChange={({ key, value, index, action, type }) => {
            switch (action) {
              case 'delete':
                setArgs(args.filter((_, paramIndex) => paramIndex !== index));
                break;
              case 'edit':
                setArgs(args.map((param, paramIndex) => (paramIndex !== index ? param : [key, value, type])));
                break;
              case 'add':
                setArgs([...args, [key, value, type]]);
                break;
              default:
            }
          }}
        />
      </FormControl>
    </StepConfigLayout>
  );
}

import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { AddIcon, Button, DeleteIcon, RefreshIcon, Wrapper, useSnackbar } from '@visualfabriq/vf-ui-kit';

import { InstanceContext } from 'src/domain/instances/InstancesProvider';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { ConfirmModal } from 'src/components/organisms/ConfirmModal';
import { InstanceModal } from './components/InstanceModal';
import { InstancesTable } from './components/InstancesTable';
import { SelectedCount } from 'src/components/atoms/SelectedCount';
import { useChangeInstance } from 'src/domain/instances/useChangeInstance';
import { EMPTY_INSTANCE_NAME } from 'src/domain/instances/InstancesProvider/constants';
import { captureException } from 'src/services/sentry';
import { getErrorMessage } from 'src/api-new/bifrost/utils';

export function Instances() {
  const {
    instances,
    loading: instancesLoading,
    selectedInstance,
    api: { addInstance, deleteInstances, refresh, updateInstance },
  } = useContext(InstanceContext);

  const params = useParams() as { instanceName: 'string' };
  const changeInstance = useChangeInstance();
  const { openModal, closeModal } = useContext(ModalContext);
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const [selectedObjectsIds, setSelectedObjectsIds] = useState(new Set<string>());
  const singleSelectedInstance = useMemo(() => {
    if (selectedObjectsIds.size === 1) {
      const firstSelectedId = selectedObjectsIds.values().next().value;
      return instances.find((instance) => instance.id === firstSelectedId);
    }
    return null;
  }, [selectedObjectsIds]);

  const handleCreateInstance = async () => {
    openModal(
      <InstanceModal
        onClick={async (name) => {
          try {
            await addInstance(name);
            enqueueSuccessSnackbar(`Instance ${name} created successfully`);
          } catch (error) {
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            enqueueErrorSnackbar(error.message || 'Something went wrong');
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
      />,
    );
  };

  const handleUpdateInstance = async () => {
    if (!singleSelectedInstance) {
      return;
    }
    openModal(
      <InstanceModal
        name={singleSelectedInstance.name}
        onClick={async (name) => {
          try {
            await updateInstance({ id: singleSelectedInstance.id, name });
            enqueueSuccessSnackbar(`Instance updated to ${name}`);
          } catch (error) {
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            enqueueErrorSnackbar(getErrorMessage(error));
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
      />,
    );
  };

  const handleDeleteInstances = async () => {
    openModal(
      <ConfirmModal
        onConfirm={async () => {
          try {
            const deletedIds = await deleteInstances(Array.from(selectedObjectsIds.values()));
            if (deletedIds.has(params.instanceName)) {
              //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
              changeInstance(instances.filter(({ id }) => !deletedIds.has(id))[0].name);
            }
            setSelectedObjectsIds(new Set());
            enqueueSuccessSnackbar('Instance has been deleted!');
          } catch (error) {
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            enqueueErrorSnackbar(getErrorMessage(error) || 'Something went wrong');
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
        question="Are you sure to delete the selected instance(s)?"
      />,
    );
  };

  const handleSelect = (items: { id: string; selected: boolean }[]) => {
    const selectedIds = new Set(items.filter(({ selected }) => selected).map(({ id }) => id));
    setSelectedObjectsIds(selectedIds);
  };

  const handleRefresh = async () => {
    const refreshedIds = await refresh();
    if (!refreshedIds.has(selectedInstance.id)) {
      changeInstance(EMPTY_INSTANCE_NAME);
    }
  };

  return (
    <Wrapper direction="column" gap={400}>
      <Wrapper gap={200}>
        <Button
          disabled={instancesLoading}
          onClick={singleSelectedInstance ? handleUpdateInstance : handleCreateInstance}
          startEnhancer={AddIcon}
        >
          {singleSelectedInstance ? 'Update Instance' : 'Create Instance'}
        </Button>
        <Button
          startEnhancer={DeleteIcon}
          kind="secondary"
          disabled={instancesLoading || !selectedObjectsIds.size}
          onClick={handleDeleteInstances}
        >
          Delete Instance(s)
        </Button>
        <Button kind="secondary" startEnhancer={RefreshIcon} onClick={handleRefresh} disabled={instancesLoading}>
          Refresh contents
        </Button>
        {selectedObjectsIds.size >= 1 && <SelectedCount count={selectedObjectsIds.size} />}
      </Wrapper>
      <InstancesTable instances={instances} onSelect={handleSelect} loading={instancesLoading} />
    </Wrapper>
  );
}

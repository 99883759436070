import { useState } from 'react';
import { Input, Button, FormLoadingBox, FormControl, Checkbox, Wrapper } from '@visualfabriq/vf-ui-kit';

//@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
import { getRelativePath } from 'src/domain/pipelines/getRelativePath';

import { SftpOutboundBearerStep } from 'src/dto/PipelineStep';
import { useInstances } from 'src/components/hooks/useInstances';
import { FileFormItem } from 'src/components/organisms/FileFormItem';
import { StepConfigLayout } from '../StepConfigLayout';

type Props = {
  step: SftpOutboundBearerStep;
  defaultFieldsConfiguration: JSX.Element;
  loading: boolean;
  onUpdate: (step: SftpOutboundBearerStep) => void;
  deleteButton: JSX.Element;
};

export function SftpOutboundBearerStepConfig(props: Props) {
  const { step, onUpdate, defaultFieldsConfiguration, loading, deleteButton } = props;
  const { configuration } = step;

  const { selectedInstance } = useInstances();

  const [port, setPort] = useState(configuration?.connection_config?.port);
  const [host, setHost] = useState(configuration?.connection_config?.host_name ?? '');
  const [username, setUsername] = useState(configuration?.connection_config?.username ?? '');
  const [moveAfterUploadToProcessed, setMoveAfterUploadToProcessed] = useState(
    !!configuration.move_after_upload_to_processed,
  );
  const [password, setPassword] = useState(configuration?.connection_config?.password ?? '');
  const [destinationPath, setDestinationPath] = useState(configuration?.destination_path);
  const [fileSearchExpression, setFileSearchExpression] = useState(
    configuration?.file_search_expression
      ? getRelativePath({ path: configuration?.file_search_expression, instance: selectedInstance })
      : '',
  );
  const [privateKeyValue, setPrivateKeyValue] = useState<string | null>(
    configuration.connection_config.rsa_pkey ?? null,
  );

  const isValid = host && fileSearchExpression && (privateKeyValue || password);

  const handleUpdate = async () => {
    onUpdate({
      ...step,
      configuration: {
        ...step.configuration,
        file_search_expression: `${selectedInstance.name}/${fileSearchExpression}`,
        destination_path: destinationPath,
        move_after_upload_to_processed: moveAfterUploadToProcessed,
        connection_config: {
          ...step.configuration.connection_config,
          port,
          host_name: host,
          rsa_pkey: privateKeyValue,
          password: password ? password : null,
          username,
        },
      },
    });
  };

  return (
    <StepConfigLayout
      loading={loading}
      loader={<FormLoadingBox items={['text', 'text', 'text', 'text', 'block', 'buttonGroup']} itemsCount={6} />}
      footer={
        <Wrapper gap="200" direction="column">
          <Button disabled={!isValid} onClick={handleUpdate}>
            Update Step
          </Button>
          {deleteButton}
        </Wrapper>
      }
    >
      {defaultFieldsConfiguration}

      <FormControl label="Host" required>
        <Input value={host} onChange={(event) => setHost(event.target.value)} />
      </FormControl>

      <FormControl label="Port">
        <Input value={port ?? ''} onChange={(event) => setPort(parseInt(event.target.value))} />
      </FormControl>

      <FormControl label="Username">
        <Input value={username} onChange={(event) => setUsername(event.target.value)} />
      </FormControl>

      <FormControl label="Password">
        <Input value={password} onChange={(event) => setPassword(event.target.value)} />
      </FormControl>

      <FormControl
        label="Destination Path"
        caption={
          <span>
            Leave <code>/</code> to place in root folder
          </span>
        }
      >
        <Input value={destinationPath} onChange={(event) => setDestinationPath(event.target.value)} />
      </FormControl>

      <Checkbox
        checked={moveAfterUploadToProcessed}
        onChange={(event) => {
          setMoveAfterUploadToProcessed(event.target.checked);
        }}
      >
        Move after upload to processed
      </Checkbox>

      <FormControl label="File Pattern" caption={`${selectedInstance.name}/${fileSearchExpression}`} required>
        <Input value={fileSearchExpression} onChange={(event) => setFileSearchExpression(event.target.value)} />
      </FormControl>

      <FileFormItem
        label="RSA Private Key"
        required={!password}
        onChange={setPrivateKeyValue}
        initialAttached={!!step.configuration.connection_config.rsa_pkey}
      />
    </StepConfigLayout>
  );
}

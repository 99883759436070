import { DataProcessingConfigInput, DataStagingConfigInput } from 'src/api-new/bifrost';
import * as DataProcessingStep from 'src/dto/PipelineStep/DataProcessingStep';
import { PipelineStepType } from 'src/dto/PipelineStep/PipelineStepType';
import { getUuidV4Parts } from 'src/utils/getUuidV4Parts';

export function getDataProcessingStep(
  stepConfiguration: DataProcessingConfigInput,
  pipelineId: string,
  filledArgs: Record<string, string>,
) {
  //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
  const kwargs = stepConfiguration.args.reduce<DataProcessingStep.DataProcessingArgs>((obj, templateArg) => {
    return {
      ...obj,
      [templateArg.key]: { value: filledArgs[templateArg.key], type: templateArg.type },
    };
  }, {});
  const { timeMid } = getUuidV4Parts(stepConfiguration.id);

  return {
    name: `Data Processing ${timeMid}`,
    type: PipelineStepType.data_processing,
    description: '',
    configuration: {
      function_link: stepConfiguration.function_link,
      kwargs,
      pass_function_runner_logger: false,
      job_timeout: stepConfiguration.job_timeout,
      ecr_image: stepConfiguration.image,
      job_size: stepConfiguration.job_size || null,
      can_be_stopped: stepConfiguration.can_be_stopped ?? true,
    },
    pipeline_id: pipelineId,
  };
}

export function getDataStagingStep(stepConfiguration: DataStagingConfigInput, pipelineId: string, instanceId: string) {
  if (!stepConfiguration.exported_from_file) {
    throw new Error(`Mission exported_from_file for ${stepConfiguration.id}`);
  }

  const exported = stepConfiguration.exported_from_file;
  const { timeMid } = getUuidV4Parts(stepConfiguration.id);

  return {
    name: `Data Staging ${timeMid}`,
    type: PipelineStepType.data_staging,
    description: '',
    configuration: {
      files_configuration_model: exported.files_configuration_model,
      encoding: exported.encoding,
      file_type_parameters: exported.file_type_parameters,
      instance_name: instanceId,
      import_folder: exported.import_folder,
      sap_system_name: exported.sap_system_name,
      table_type: exported.table_type,
      pipeline_id: pipelineId,
    },
    pipeline_id: pipelineId,
  };
}

import { BadResponse } from '../types';

export async function handleResponse(response: Response): Promise<unknown> {
  if (response.ok) {
    return response.json();
  } else {
    let errorMessage = '';
    try {
      const { detail } = (await response.json()) as BadResponse;
      errorMessage = detail;
    } catch {
      errorMessage = (await response.text()) || 'Something went wrong';
    }
    throw new Error(errorMessage);
  }
}

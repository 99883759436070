import { UsageLogAllBodyFiltersInner } from 'src/api-new/bullis';
import { Filters } from './types';

export function getActionDisplayName(action: string) {
  switch (action) {
    case 'CREATE':
      return 'Create';
    case 'UPDATE':
      return 'Update';
    case 'DELETE':
      return 'Delete';
    default:
      return action;
  }
}

export function getObjectTyoDisplayName(objectType: string) {
  switch (objectType) {
    case 'pipeline':
      return 'Pipeline';
    default:
      return objectType;
  }
}

const OBJ_FILTERS_KEYS = ['pipeline_id'] as const;

export function formatFilters(filters: Filters) {
  const formatFilters: UsageLogAllBodyFiltersInner[] = [];

  const objIdFilters = filters.filter((f) => OBJ_FILTERS_KEYS.includes(f.key)).flatMap((f) => f.exact_value);
  if (objIdFilters.length) {
    formatFilters.push({ key: 'object_identifier', condition: 'in', exact_value: objIdFilters });
  }
  return formatFilters;
}

export function formatContext(context: string | null | undefined) {
  if (!context) return 'No context';
  try {
    return JSON.stringify(JSON.parse(context), null, 2);
  } catch {
    return context;
  }
}

import { useState } from 'react';
import { Button, Wrapper, useSnackbar } from '@visualfabriq/vf-ui-kit';

import { FileFormItem } from 'src/components/organisms/FileFormItem';

import { Form, FormState } from './Form';
import { ScheduleType } from '../constants';
import { DayOfWeek, replaceCron } from 'src/utils/awsCron/utils';
import { ExportedPipeline } from '../../types';

type Props = {
  onCreate: (args: {
    name: string;
    schedule: string;
    description: string;
    importedConfiguration: ExportedPipeline | null;
    timeZone: string;
  }) => void;
};

export function CreateManually({ onCreate }: Props) {
  const [formState, setFormState] = useState<FormState>({
    name: '',
    schedule: '',
    selectedSchedule: ScheduleType.Daily,
    description: '',
    timeZone: 'GMT',
  });
  const [importedConfiguration, setImportConfiguration] = useState<ExportedPipeline | null>(null);
  const [isScheduleValid, setIsScheduleValid] = useState(false);
  const snackbar = useSnackbar();

  const handleConfigurationImport = (fileContent: string | null) => {
    if (fileContent === null) {
      setImportConfiguration(null);
      return;
    }

    try {
      const importedConfiguration = JSON.parse(fileContent) as ExportedPipeline;

      setFormState({
        name: importedConfiguration.name ?? formState.name,
        schedule: replaceCron(importedConfiguration.schedule ?? formState.schedule, { dow: DayOfWeek.awsToStandard }),
        selectedSchedule: ScheduleType.Custom,
        description: importedConfiguration.description ?? formState.description,
        timeZone: importedConfiguration.time_zone ?? formState.timeZone,
      });

      setImportConfiguration(importedConfiguration);
      setIsScheduleValid(importedConfiguration.schedule ? true : isScheduleValid);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      snackbar.enqueueErrorSnackbar(error.message);
    }
  };

  const handleCreate = () => {
    const { name, description, schedule, timeZone } = formState;
    onCreate({
      name,
      description,
      schedule: replaceCron(schedule, { dow: DayOfWeek.standardToAWS }),
      importedConfiguration,
      timeZone,
    });
  };

  const ifValid = (formState.selectedSchedule === ScheduleType.Minute || isScheduleValid) && formState.name;
  return (
    <Wrapper gap={200} direction="column">
      <Form
        value={formState}
        onChange={(event) => setFormState((s) => ({ ...s, ...event }))}
        onScheduleValidChange={setIsScheduleValid}
      />
      <FileFormItem label="Import Configuration" onChange={handleConfigurationImport} />
      <Wrapper justifyContent="end">
        <Button size="large" disabled={!ifValid} onClick={handleCreate}>
          Create
        </Button>
      </Wrapper>
    </Wrapper>
  );
}

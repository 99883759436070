import React, { useEffect, useState } from 'react';

import { useUser } from 'src/services/useUser';
import { getUserPermissions as fetchUserPermissions } from 'src/api/auth/permissions';
import { useInstances } from 'src/components/hooks/useInstances';
import { getSecurityDBUser } from 'src/api/auth/getSecurityDBUser';
import { VFModuleCodes } from 'src/api-new/bifrost';
import { UserPermission, UserPermissionName } from './types';
import { captureException } from 'src/services/sentry';

type UserPermissionsState = {
  permissions: Set<UserPermission>;
  managedModules: Set<VFModuleCodes>;
  has: (name: UserPermissionName) => boolean;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any | null;
};

// eslint-disable-next-line react-refresh/only-export-components
export const UserPermissionsContext = React.createContext<UserPermissionsState>({} as UserPermissionsState);

export const UserPermissionsProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessToken } = useUser();
  const { selectedInstance } = useInstances();
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState<Set<UserPermission>>(new Set());
  const [managedModules, setManagedModules] = useState<Set<VFModuleCodes>>(new Set());
  const [isProductsMangedLoading, setProductsMangedLoading] = useState(true);
  const [isLoadingPermissionsForInstance, setIsLoadingPermissionsForInstance] = useState<Record<string, boolean>>({});

  useEffect(() => {
    async function getModulesManaged() {
      try {
        setProductsMangedLoading(true);
        const user = await getSecurityDBUser(getAccessToken);
        setManagedModules(new Set(user.managed_bifrost_vf_module_codes));
      } catch (error) {
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        setError(error);
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        captureException(error);
      } finally {
        setProductsMangedLoading(false);
      }
    }

    getModulesManaged();
  }, []);

  useEffect(() => {
    async function getUserPermissionsForInstance(instanceId: string) {
      try {
        setIsLoadingPermissionsForInstance({ [instanceId]: true });
        const userPermissions = await fetchUserPermissions(instanceId, getAccessToken);
        setPermissions(new Set(userPermissions));
      } catch (error) {
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        setError(error);
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        captureException(error);
      } finally {
        setIsLoadingPermissionsForInstance({ [instanceId]: false });
      }
    }

    if (selectedInstance) {
      getUserPermissionsForInstance(selectedInstance.id);
    }
  }, [selectedInstance]);

  const hasPermission = (name: UserPermissionName) => permissions.has(UserPermission[name]);

  const isLoadingPermissionsForCurrenInstance = isLoadingPermissionsForInstance[selectedInstance?.id] ?? true;
  return (
    <UserPermissionsContext.Provider
      value={{
        permissions,
        managedModules,
        has: hasPermission,
        isLoading: isLoadingPermissionsForCurrenInstance || isProductsMangedLoading,
        error,
      }}
    >
      {children}
    </UserPermissionsContext.Provider>
  );
};

import {
  Button,
  ContentWrapper,
  FormControl,
  Input,
  NoContent,
  Accordion,
  Panel,
  Wrapper,
} from '@visualfabriq/vf-ui-kit';
import { useState } from 'react';
import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

import { TableConfiguration } from '../TableConfiguration';
import { CollectionPanelTitle } from './CollectionPanelTitle';

export function Form({
  value,
  onChange,
  onVerboseChange = onChange,
  isCreatingModal = false,
}: {
  value: FormValue;
  onChange: (value: FormValue) => void;
  onVerboseChange?: (value: FormValue) => void;
  isCreatingModal?: boolean;
}) {
  const [unsavedCollections, setUnsavedCollections] = useState(new Set<number>());
  const [expandedCollectionIndex, setExpandedCollectionIndex] = useState<null | number>();

  const handleImportParametersUpdate = (indexToUpdate: number) => (updatedParameters: ImportParameters) => {
    setUnsavedCollections(new Set(unsavedCollections.add(indexToUpdate)));
    onChange({
      ...value,
      importParameters: value.importParameters.map((parameters, index) =>
        index === indexToUpdate ? updatedParameters : parameters,
      ),
    });
  };

  const handleImportParametersDelete = (indexToUpdate: number) => () => {
    unsavedCollections.delete(indexToUpdate);
    if (expandedCollectionIndex === indexToUpdate) {
      setExpandedCollectionIndex(null);
    }
    setUnsavedCollections(new Set(unsavedCollections));
    onVerboseChange({
      ...value,
      importParameters: value.importParameters.filter((_, index) => index !== indexToUpdate),
    });
  };

  const handleImportTableCreate = () => {
    onChange({
      ...value,
      importParameters: value.importParameters.map((param, index, self) =>
        index != self.length - 1 ? param : { ...param, isDraft: false },
      ),
    });
  };

  const handleAddImportParameters = () => {
    const newImportParameters = [...value.importParameters, draftImportParameterState];
    onChange({
      ...value,
      importParameters: newImportParameters,
    });
    setExpandedCollectionIndex(newImportParameters.length - 1);
  };

  //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
  const handleCollectionExpandChange = (index) => (event) => {
    if (event.expanded) {
      setExpandedCollectionIndex(index);
    } else {
      setExpandedCollectionIndex(null);
    }
  };

  return (
    <>
      <FormControl label="Fnmatch Expression" disabled={false} required>
        <Input
          value={value.fnmatchExpression}
          onChange={(event) => onChange({ ...value, fnmatchExpression: event.target.value })}
        />
      </FormControl>

      <FormControl label="Lower Level Key" disabled={false} caption="Supported for XML or JSON file types only">
        <Input
          value={value.lowerLevelKey}
          onChange={(event) => onChange({ ...value, lowerLevelKey: event.target.value })}
        />
      </FormControl>

      {value.importParameters.length ? (
        <FormControl label="Collections" required>
          <Accordion>
            {value.importParameters.map((parameters, index) => {
              const expanded = expandedCollectionIndex === index;
              return (
                <Panel
                  onChange={handleCollectionExpandChange(index)}
                  expanded={expandedCollectionIndex === index}
                  title={
                    <CollectionPanelTitle
                      keyFromFile={parameters.keyFromFile}
                      targetCollection={parameters.targetCollection}
                      isCreatingModal={isCreatingModal}
                      isDraft={parameters.isDraft}
                      isUnsaved={unsavedCollections.has(index)}
                    />
                  }
                  key={`${index}-${expanded}`}
                >
                  <ContentWrapper>
                    <TableConfiguration
                      isDraft={parameters.isDraft}
                      state={parameters}
                      onUpdate={handleImportParametersUpdate(index)}
                      onCreate={handleImportTableCreate}
                      onDelete={handleImportParametersDelete(index)}
                      showTransformations={!isCreatingModal}
                    />
                  </ContentWrapper>
                </Panel>
              );
            })}
          </Accordion>
        </FormControl>
      ) : (
        <NoContent message="Add a least on collection" />
      )}

      <Wrapper gap="200" marginBlock="200">
        <Button disabled={value.importParameters.some(({ isDraft }) => isDraft)} onClick={handleAddImportParameters}>
          Add Collection
        </Button>
      </Wrapper>
    </>
  );
}

export type FormValue = {
  fnmatchExpression: string;
  lowerLevelKey: string;
  importParameters: ImportParameters[];
};

export type ImportParameters = {
  keyFromFile: string;
  targetCollection: string;
  persistMethod: DataStagingStep.PersistMethod;
  updateKeys: string;
  mandatoryKeys: string;
  inheritKeys: string;
  transformations: DataStagingStep.TransformationModel[];
  isDraft: boolean;
};

const draftImportParameterState: ImportParameters = {
  keyFromFile: '',
  targetCollection: '',
  persistMethod: DataStagingStep.PersistMethod.Append,
  updateKeys: '',
  mandatoryKeys: '',
  inheritKeys: '',
  transformations: [],
  isDraft: true,
};

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useBifrostApi } from 'src/services/useBifrostApi';
import { Instance, InstancesApi } from 'src/api-new/bifrost';
import { EMPTY_INSTANCE_NAME, LAST_USED_IN_STANCE_NAME_KEY } from './constants';
import { useChangeInstance } from '../useChangeInstance';
import { pathConstants } from 'src/routes';

type InstancesState = {
  instances: Instance[];
  selectedInstance: Instance;
  loading: boolean;
  api: {
    addInstance: (name: string) => Promise<void>;
    deleteInstance: (id: string) => Promise<void>;
    deleteInstances: (ids: string[]) => Promise<Set<string>>;
    updateInstance: (args: { id: string; name: string }) => Promise<void>;
    refresh: () => Promise<Set<string>>;
  };
};

// eslint-disable-next-line react-refresh/only-export-components
export const InstanceContext = React.createContext<InstancesState>(null as unknown as InstancesState);

export const InstancesProvider = ({ children }: { children: React.ReactNode }) => {
  const [instances, setInstances] = useState<Instance[]>([]);
  const [loading, setLoading] = useState(false);
  const instancesAPI = useBifrostApi(InstancesApi);

  const { instanceName } = useParams() as { instanceName: string };
  const navigate = useNavigate();
  const changeInstance = useChangeInstance();

  useEffect(() => {
    if (instanceName === EMPTY_INSTANCE_NAME && instances.length) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      const defaultInstance = localStorage.getItem(LAST_USED_IN_STANCE_NAME_KEY) ?? instances[0].name;
      changeInstance(defaultInstance);
    }
  }, [instanceName, instances]);

  const selectedInstance = useMemo(() => {
    return instances.find((instance) => instance.name === instanceName)!;
  }, [instances, instanceName]);

  useEffect(() => {
    if (instances.length && !selectedInstance && instanceName !== EMPTY_INSTANCE_NAME) {
      navigate(pathConstants.INSTANCE_NOT_FOUND, { replace: true });
    }
  }, [instances, selectedInstance]);

  useEffect(() => {
    if (!selectedInstance) return;
    localStorage.setItem(LAST_USED_IN_STANCE_NAME_KEY, selectedInstance.name);
  }, [selectedInstance]);

  useEffect(() => {
    const getInstances = async () => {
      setLoading(true);
      const { data: instances } = await instancesAPI.readInstances();

      const sortedInstances = instances.sort((a, b) => a.name.localeCompare(b.name));

      setInstances(sortedInstances);
      setLoading(false);
    };

    getInstances();
  }, []);

  const addInstance = async (name: string) => {
    try {
      setLoading(true);
      const { data: addedInstance } = await instancesAPI.createInstance({
        instanceCreateOrUpdate: { name, date_created: new Date().toJSON() },
      });
      setInstances([...instances, addedInstance].sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data) {
        error.message = error.response.data.detail;
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteInstance = async (instanceId: string) => {
    try {
      setLoading(true);
      await instancesAPI.deleteInstance({ instanceId });
      setInstances(instances.filter((instance) => instance.id !== instanceId));
    } finally {
      setLoading(false);
    }
  };

  const deleteInstances = async (ids: string[]) => {
    try {
      setLoading(true);
      const result = await Promise.all(ids.map((id) => instancesAPI.deleteInstance({ instanceId: id })));
      const deletedIds = result.reduce((ids, { data: instance }) => ids.add(instance.id), new Set<string>());
      const newInstances = instances.filter((instance) => !deletedIds.has(instance.id));
      setInstances(newInstances);

      return deletedIds;
    } finally {
      setLoading(false);
    }
  };

  const refresh = async () => {
    setLoading(true);
    const { data: instances } = await instancesAPI.readInstances();
    setInstances(instances);
    const ids = instances.reduce((ids, instance) => ids.add(instance.id), new Set<string>());
    setLoading(false);
    return ids;
  };

  const updateInstance = async ({ id, name }: { id: string; name: string }) => {
    const { data: updatedInstance } = await instancesAPI.updateInstance({ instanceId: id, instanceUpdate: { name } });

    setInstances(instances.map((instance) => (instance.id === updatedInstance.id ? updatedInstance : instance)));
  };

  return (
    <InstanceContext.Provider
      value={{
        instances,
        loading,
        selectedInstance,
        api: {
          addInstance,
          deleteInstance,
          deleteInstances,
          updateInstance,
          refresh,
        },
      }}
    >
      {children}
    </InstanceContext.Provider>
  );
};

import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { AuthConfig } from 'src/dto/auth/AuthConfig';
import { EnvVars } from 'src/services/envVars';

type Awaited<T> = T extends Promise<infer U> ? U : T;
export type CognitoUserSession = Awaited<ReturnType<typeof Auth.currentSession>>;

export type AuthState = {
  session: CognitoUserSession | null;
  isSignedIn: boolean;
};

export const useAuth = (config: AuthConfig | null) => {
  const [state, setState] = useState<AuthState>({
    session: null,
    isSignedIn: false,
  });

  useEffect(() => {
    async function checkIsUserAlreadySignedIn() {
      try {
        await getUserSession();
      } catch (error) {
        // do nothing
        // signIn callback will fully refresh the page
        if (EnvVars.isDevMode) {
          console.error(error);
        }
      }
    }

    if (config?.options) {
      Auth.configure(config.options);
      checkIsUserAlreadySignedIn();
    }
  }, [config?.options]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const signIn = () => Auth.federatedSignIn({ provider: config?.provider as any });

  const signOut = () => Auth.signOut();

  const getUserSession = async () => {
    const session = await Auth.currentSession();
    // We don't need to update the state each time, only when jwt token been refreshed
    if (state.session?.getAccessToken().getJwtToken() !== session.getAccessToken().getJwtToken()) {
      setState({ session: session, isSignedIn: true });
    }

    return session;
  };

  return {
    session: state.session,
    isSignedIn: state.isSignedIn,
    api: {
      signIn,
      signOut,
      getUserSession,
    },
  };
};

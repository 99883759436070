import { useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { AddIcon, LabelMedium, RemoveIcon, styled, Tag, useStyletron } from '@visualfabriq/vf-ui-kit';
import { Theme } from '@visualfabriq/vf-ui-kit/dist/themes/types';
import { textBlockOverrides } from 'src/components/styles';

type CollapsibleProps = {
  //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
  onOpenChange?: (event) => void;
  tagText?: string;
  initialOpenState?: boolean;
  children: React.ReactNode;
  heading: React.ReactNode;
  borderColor?: keyof Theme['colors'];
  headerBackgroundColor?: keyof Theme['colors'];
  backgroundColor?: keyof Theme['colors'];
  padding?: string;
  margin?: string;
  width?: string;
};

export const Collapse = (props: CollapsibleProps) => {
  const [css, theme] = useStyletron();
  const {
    onOpenChange,
    children,
    heading,
    borderColor,
    backgroundColor,
    headerBackgroundColor,
    padding,
    margin = `${theme.sizing.scale400} 0`,
    initialOpenState = false,
    tagText,
    width,
  } = props;
  const [open, setOpen] = useState(initialOpenState);

  const handleOpenChange = (event: boolean) => {
    setOpen(event);
    if (onOpenChange) {
      onOpenChange(event);
    }
  };

  return (
    <Collapsible.Root
      className={css({
        margin,
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        backgroundColor: backgroundColor ? theme.colors[backgroundColor] : 'initial',
      })}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Collapsible.Trigger
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          width: width ?? '100%',
          height: '48px',
          lineHeight: '48px',
          paddingLeft: theme.sizing.scale300,
          fontSize: '16px',
          fontWeight: '500',
          background: 'none',
          border: 'none',
          textAlign: 'start',
          //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
          backgroundColor: headerBackgroundColor ? theme.colors[headerBackgroundColor] : 'white',
          borderBottom: `${theme.borders.border200.borderWidth} solid ${
            borderColor ?? theme.borders.border200.borderColor
          }`,
        })}
      >
        <Heading>
          <LabelMedium marginBottom="0" overrides={textBlockOverrides}>
            {heading}
          </LabelMedium>
          {tagText && (
            <Tag size="small" color="orange">
              {tagText}
            </Tag>
          )}
        </Heading>
        <CollapseIcon>{open ? <RemoveIcon /> : <AddIcon />}</CollapseIcon>
      </Collapsible.Trigger>
      <Collapsible.Content
        className={css({
          width: width ?? '100%',
          ...(padding ? { padding } : {}),
        })}
      >
        {children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

const CollapseIcon = styled('div', () => ({
  width: '64px',
  textAlign: 'center',
}));

const Heading = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'hidden',
  alignItems: 'center',
  height: '100%',
}));

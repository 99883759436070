import { useState } from 'react';

import { FileUploader, FormControl, Tag } from '@visualfabriq/vf-ui-kit';

type FileFormItemProps = {
  label: string;
  required?: boolean;
  initialAttached?: boolean;
  onChange: (file: string | null) => void;
};

export function FileFormItem(props: FileFormItemProps) {
  const { label, required = false, onChange, initialAttached = false } = props;
  const [attached, setAttached] = useState(initialAttached);
  const [file, setFile] = useState<File | null>(null);

  const handleOnDrop = async (files: File[]) => {
    const file = files[0];
    //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    setFile(file);
    //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    onChange(await files[0].text());
  };

  const handleRestFile = () => {
    setFile(null);
    onChange(null);
  };

  const handleDropAttached = () => {
    setAttached(false);
    setFile(null);
    onChange(null);
  };

  if (attached) {
    return (
      <FormControl label={label} required={required}>
        <Tag color="blue" closeable onActionClick={handleDropAttached}>
          Attached
        </Tag>
      </FormControl>
    );
  }

  return (
    <FormControl label={label} required={required}>
      {file ? (
        <Tag color="blue" closeable onActionClick={handleRestFile}>
          {file.name}
        </Tag>
      ) : (
        <FileUploader onDrop={handleOnDrop} />
      )}
    </FormControl>
  );
}

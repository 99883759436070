import { Checkbox } from '@visualfabriq/vf-ui-kit';
import { PivotColumnValues } from 'src/api-new/bifrost';

type Props = {
  fields: PivotColumnValues;
  onUpdate: (fields: PivotColumnValues) => void;
};

export function PivotColumnValuesMethod(props: Props) {
  const { fields, onUpdate } = props;

  return (
    <>
      <Checkbox
        checked={fields.keep_columns}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(event) => onUpdate({ ...fields, keep_columns: !!(event.target as any).checked })}
      >
        Keep Columns
      </Checkbox>
    </>
  );
}

import {
  Button,
  DURATION,
  HeadingXSmall,
  LabelSmall,
  NoContent,
  ReportingIcon,
  Wrapper,
  styled,
  useSnackbar,
  useStyletron,
} from '@visualfabriq/vf-ui-kit';
import { useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useAsync, useAsyncRetry } from 'react-use';
import { DbDocumentsApi, DocumentFilter } from 'src/api-new/bifrost';
import { Resizer } from 'src/components/atoms/Resizer';
import { ErrorGeneral } from 'src/components/ErrorGeneral';
import { useDocumentPagination } from 'src/components/hooks';
import { useInstances } from 'src/components/hooks/useInstances';
import { useResize } from 'src/components/hooks/useResize';
import { List, ListHeader, ListItem } from 'src/components/organisms/List';
import { Pagination } from 'src/components/organisms/List/ListPagination';
import { InsightsContext } from 'src/domain/insights';
import { DocumentFiltersOperatorUIOnly, FiltersOperator } from 'src/domain/insights/types';
import { DbDocument } from 'src/dto/dbDocuments/DdDocument';
import { DocumentsExportService } from 'src/services/DocumentsExportService';
import { captureException } from 'src/services/sentry';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { DOCUMENTS_PER_PAGE } from './constants';
import { DocumentsFilter } from './DocumentsFilter';

const DOCUMENTS_LIST_TEST_ID = 'documentsListTestId';

const actionsHeight = '50px';
const Actions = styled('div', () => ({
  height: actionsHeight,
}));

const DocumentsContent = styled('div', () => ({
  display: 'flex',
  height: `calc(100% - ${actionsHeight})`,
}));

export function Collection() {
  const { collectionName } = useParams();
  const { selectedInstance } = useInstances();
  const dbDocumentsApi = useBifrostApi(DbDocumentsApi);
  const snackbar = useSnackbar();
  const [css, theme] = useStyletron();
  const [resizeRef, width] = useResize<HTMLDivElement>({ initialWidth: 250, minWidth: 250, maxWidths: 500 });
  const documentsExportService = useMemo(() => new DocumentsExportService(dbDocumentsApi), [dbDocumentsApi]);
  const { filters, setFilters, filtersOperator, setFiltersOperator } = useContext(InsightsContext);

  const { value: docCount } = useAsync(async () => {
    if (!collectionName) return;

    try {
      const { data } = await dbDocumentsApi.getDocumentsCount({
        documentsQueryBase: {
          instance_id: selectedInstance.id,
          collection_name: collectionName,
          filters,
          ...(filtersOperator !== DocumentFiltersOperatorUIOnly.None ? { filters_operator: filtersOperator } : {}),
        },
      });
      return data.documents_count;
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      captureException(error);
      snackbar.enqueueErrorSnackbar(`Failed to load documents count for ${collectionName}`);
      return undefined;
    }
  }, [selectedInstance.id, collectionName, filters, collectionName]);

  const { skip, index, pages, next, previous, reset } = useDocumentPagination(docCount ?? 0, DOCUMENTS_PER_PAGE);

  const { loading, retry, error, value } = useAsyncRetry(fetchDocuments, [
    skip,
    collectionName,
    selectedInstance.id,
    filters,
    filtersOperator,
  ]);

  const handleFilterChange = (documentFilters: DocumentFilter[], filtersOperator: FiltersOperator) => {
    reset();
    setFilters(documentFilters);
    setFiltersOperator(filtersOperator);
  };

  async function fetchDocuments() {
    try {
      const { data } = await dbDocumentsApi.getDocuments({
        documentsQueryPagination: {
          instance_id: selectedInstance.id,
          collection_name: collectionName!,
          filters,
          ...(filtersOperator !== DocumentFiltersOperatorUIOnly.None ? { filters_operator: filtersOperator } : {}),
          limit: DOCUMENTS_PER_PAGE,
          skip,
        },
      });
      return data as Array<DbDocument>;
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      captureException(error);
      snackbar.enqueueErrorSnackbar(`Failed to load documents for ${collectionName}`);
      return new Array<DbDocument>();
    }
  }

  const exportCollection = async () => {
    if (!collectionName) return;

    try {
      snackbar.enqueue({ message: 'Exporting... Please wait', progress: true }, DURATION.infinite);
      documentsExportService.export({
        instanceId: selectedInstance.id,
        collectionName: collectionName,
        filterOperator: filtersOperator === DocumentFiltersOperatorUIOnly.None ? undefined : filtersOperator,
        filters,
        onProgress: ({ message, type }) => {
          const isProgress = type === 'progress';
          snackbar.dequeue();
          snackbar.enqueue({ message, progress: isProgress }, isProgress ? DURATION.infinite : DURATION.short);
        },
      });
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      captureException(error);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      snackbar.enqueueErrorSnackbar(error.message);
    }
  };

  if (error) {
    return <ErrorGeneral title="Something went wrong" description={error.message} />;
  }

  return (
    <Wrapper direction="column" className={css({ overflow: 'hidden' })}>
      <Actions>
        <DocumentsFilter documentFilters={filters} filtersOperator={filtersOperator} onChange={handleFilterChange} />
      </Actions>
      <DocumentsContent>
        <Wrapper marginInline={`0 ${theme.sizing.scale800}`} data-testid={DOCUMENTS_LIST_TEST_ID}>
          <div style={{ width }}>
            <List
              heading={
                <Wrapper marginBlock={`0 ${theme.sizing.scale800}`}>
                  <ListHeader onRefresh={retry}>
                    <Wrapper gap={200} alignItems="center">
                      <HeadingXSmall marginBottom="0">Documents</HeadingXSmall>
                      {docCount && (
                        <LabelSmall marginBottom="0" kind="tertiary">
                          ({docCount})
                        </LabelSmall>
                      )}
                      <Button kind="secondary" startEnhancer={ReportingIcon} onClick={exportCollection} size="mini">
                        Export
                      </Button>
                    </Wrapper>
                  </ListHeader>
                </Wrapper>
              }
              loading={loading}
              style={{ overflow: 'auto', height: 'calc(100% - 42px)', width: '100%' }}
              loadingItems={DOCUMENTS_PER_PAGE}
            >
              {value?.length ? (
                value.map(({ _id }) => <ListItem key={_id} item={{ id: _id, name: _id }} />)
              ) : (
                <NoContent message="No documents to show" />
              )}
            </List>
            {pages > 0 && <Pagination index={index} pages={pages} next={next} previous={previous} />}
          </div>
          <Resizer ref={resizeRef} />
        </Wrapper>
        <Outlet />
      </DocumentsContent>
    </Wrapper>
  );
}

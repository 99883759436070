import { useState } from 'react';
import { FileUploader, Tag } from '@visualfabriq/vf-ui-kit';

export function ImportConfiguration<TConfiguration>(props: {
  onChange: (configuration: TConfiguration | null) => void;
  onError: (error: Error) => void;
}) {
  const [filename, setFilename] = useState<string>();
  const { onChange, onError } = props;

  const handleDetachFile = () => {
    onChange(null);
    setFilename(undefined);
  };

  if (filename) {
    return (
      <div>
        <Tag closeable onActionClick={handleDetachFile} color="blue" size="small">
          {filename}
        </Tag>
      </div>
    );
  }

  return (
    <FileUploader
      onDrop={async (files) => {
        try {
          const file = files[0];
          //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
          const configText = await file.text();
          const configuration = JSON.parse(configText);
          //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
          setFilename(file.name);
          onChange(configuration as TConfiguration);
        } catch (error) {
          //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
          onError(error);
        }
      }}
      overrides={{
        FileDragAndDrop: {
          style: () => ({
            height: '90px',
            padding: '10px',
          }),
        },
      }}
    />
  );
}

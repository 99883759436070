import React from 'react';
import axios, { AxiosInstance } from 'axios';

import { useUser } from 'src/services/useUser';

// eslint-disable-next-line react-refresh/only-export-components
export const AuthorizedAxiosContext = React.createContext<AxiosInstance>({} as AxiosInstance);

export function AuthorizedAxiosProvider({ children }: { children: React.ReactNode }) {
  const { getAccessToken } = useUser();

  const apiClient = axios.create();
  apiClient.interceptors.request.use(async (config) => {
    const accessToken = await getAccessToken();
    config.headers!.Authorization = accessToken;
    return config;
  });

  return <AuthorizedAxiosContext.Provider value={apiClient}>{children}</AuthorizedAxiosContext.Provider>;
}

import { useAsync } from 'react-use';
import { useParams } from 'react-router-dom';
import { HeadingXSmall, NoContent, Skeleton, styled, useSnackbar } from '@visualfabriq/vf-ui-kit';

import { JsonContentViewer } from 'src/components/atoms/JsonContentViewer';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { DbDocumentsApi } from 'src/api-new/bifrost';
import { useInstances } from 'src/components/hooks/useInstances';
import { ErrorGeneral } from 'src/components/ErrorGeneral';
import { ContentViewerHeader } from './ContentViewerHeader';
import { DocumentWrapper } from './DocumentWerapper';

export function Document() {
  const { documentId, collectionName } = useParams() as { documentId: string; collectionName: string };
  const { selectedInstance } = useInstances();
  const snackbar = useSnackbar();
  const dbDocumentsApi = useBifrostApi(DbDocumentsApi);

  const documentState = useAsync(async (): Promise<Record<string, unknown>> => {
    const { data } = await dbDocumentsApi.getDocuments({
      documentsQueryPagination: {
        collection_name: collectionName!,
        instance_id: selectedInstance.id,
        filters: [{ field: '_id', type: 'object_id', query: documentId, operator: '$eq' }],
        skip: 0,
        limit: 1,
      },
    });
    return data[0];
  }, [collectionName, selectedInstance.id, documentId]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(documentState.value, null, 2));
    snackbar.enqueueInfoSnackbar('Copied to clipboard!');
  };

  if (documentState.error) {
    return (
      <DocumentWrapper>
        <HeadingXSmall>Document details</HeadingXSmall>
        <ErrorGeneral title="Something went wrong" description={documentState.error.message} />
      </DocumentWrapper>
    );
  }

  if (documentState.loading || false) {
    return (
      <DocumentWrapper>
        <HeadingXSmall>Document details</HeadingXSmall>
        <Skeleton width="100%" height="50%" />
      </DocumentWrapper>
    );
  }

  if (!documentState.value) {
    return (
      <DocumentWrapper>
        <Heading>
          <HeadingXSmall>Document details</HeadingXSmall>
        </Heading>
        <NoContent />
      </DocumentWrapper>
    );
  }

  return (
    <DocumentWrapper>
      <Heading>
        <HeadingXSmall>Document details</HeadingXSmall>
        <ContentViewerHeader displayName={documentId} onCopy={handleCopyToClipboard} />
      </Heading>

      <JsonContentViewer
        style={{ borderTop: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        content={JSON.stringify(documentState.value, null, 2)}
      />
    </DocumentWrapper>
  );
}

const Heading = styled('div', () => ({
  backgroundColor: 'white',
  position: 'sticky',
  top: '-1px',
}));

import { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  ContentWrapper,
  NoContent,
  Panel,
  Skeleton,
  Wrapper,
  useSnackbar,
} from '@visualfabriq/vf-ui-kit';
import { v4 as uuid4 } from 'uuid';

import { TemplateHeading } from './TemplateHeading';
import { TemplateFieldsForm } from './TemplateFieldsForm';
import { PipelineStepType } from 'src/dto/PipelineStep/PipelineStepType';
import { TemplateSteps } from './TemplateSteps';
import { StepHeading } from './StepHeading';
import { TemplateStepForm } from './TemplateSteps/TemplateStepForm';
import { DRAFT_DATA_PROCESSING_STEP, DRAFT_DATA_STAGING_STEP, DraftableStepConfiguration } from './drafts';
import {
  PipelineTemplate,
  PipelineTemplateCreate,
  PipelineTemplateUpdate,
  PipelineTemplatesApi,
  PipelineTemplateStepsConfigurationInner,
} from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { DraftTemplate } from '../constants';

type Props = {
  template: { id: string; label: string };
  draftTemplate: DraftTemplate | null;
  action: 'create' | 'update';
  onCreate: (template: Omit<PipelineTemplateCreate, 'id'>) => void;
  onUpdate: (event: { id: string; templateUpdate: PipelineTemplateUpdate }) => void;
  onDelete: (id: string) => void;
  onCancel: () => void;
};

export function Template(props: Props) {
  const { template, draftTemplate, action, onUpdate, onCreate, onCancel, onDelete } = props;
  const { enqueueErrorSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [templateFormField, setTemplateFormFields] = useState<TemplateFieldsForm>();
  const [stepConfigurations, setStepConfigurations] = useState<
    (PipelineTemplateStepsConfigurationInner & { draft?: boolean })[]
  >([]);
  const [unsaved, setUnsaved] = useState(false);
  const [stepConfigurationsValidState, setStepConfigurationsValidState] = useState<Record<string, boolean>>({});

  const pipelineTemplatesApi = useBifrostApi(PipelineTemplatesApi);

  const isValid = Object.values(stepConfigurationsValidState).every(Boolean);
  const isDraft = draftTemplate !== null;

  useEffect(() => {
    if (isDraft) {
      setDraftTemplate(draftTemplate);
    } else {
      fetchTemplate(template.id);
    }
  }, [template.id, draftTemplate]);

  useEffect(() => {
    setUnsaved(false);
  }, [template]);

  const setDraftTemplate = async (draftTemplate: DraftTemplate) => {
    // dirty hack to re-render `TemplateFieldsForm` which uses `FormAutoGenerated`
    setLoading(true);
    await new Promise((resolve) => setTimeout(() => resolve(null), 10));
    updateTemplateState(draftTemplate);
    setLoading(false);
  };

  const updateTemplateState = (template: PipelineTemplateCreate) => {
    const { mandatory, label, vf_module_id, description, zendesk_url, category } = template;
    setTemplateFormFields({
      mandatory,
      label,
      vf_module_id: String(vf_module_id),
      description,
      zendesk_url,
      category,
    });
    setStepConfigurations(template.steps_configuration!);
    setStepConfigurationsValidState(
      template.steps_configuration!.reduce((state: Record<string, boolean>, conf) => {
        state[conf.id] = true;
        return state;
      }, {}),
    );
  };

  const fetchTemplate = async (pipelineTemplateId: string) => {
    try {
      setLoading(true);
      const { data: template } = await pipelineTemplatesApi.getPipelineTemplate({ pipelineTemplateId });
      updateTemplateState(template);
      setError(undefined);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = () => {
    onCreate({
      ...templateFormField!,
      zendesk_url: templateFormField?.zendesk_url || undefined,
      steps_configuration: stepConfigurations
        .filter(({ draft }) => !draft)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ draft, ...sc }) => ({ ...sc, id: uuid4() })),
    });
  };

  const handleUpdate = () => {
    onUpdate({
      id: template.id,
      templateUpdate: {
        ...templateFormField!,
        zendesk_url: templateFormField?.zendesk_url || undefined,
        steps_configuration: stepConfigurations
          .filter(({ draft }) => !draft)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(({ draft, ...rest }) => rest),
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTemplateFieldsFormChange = (event: any) => {
    setUnsaved(true);
    setTemplateFormFields(event);
  };

  const handleStepConfigurationFormChange = (event: DraftableStepConfiguration) => {
    if (!event.draft) {
      setUnsaved(true);
    }

    setStepConfigurations(stepConfigurations.map((curr) => (curr.id === event.id ? event : curr)));
  };

  const handleStepConfigurationFormValidChange = (stepConfigurationId: string, isValid: boolean) => {
    setStepConfigurationsValidState({
      ...setStepConfigurationsValidState,
      [stepConfigurationId]: isValid,
    });
  };

  const handleAddDraftStep = (type: PipelineStepType) => {
    let draftStep: DraftableStepConfiguration | null = null;

    switch (type) {
      case PipelineStepType.data_staging:
        draftStep = DRAFT_DATA_STAGING_STEP;
        break;
      case PipelineStepType.data_processing:
        draftStep = DRAFT_DATA_PROCESSING_STEP;
        break;
    }

    if (!draftStep) {
      enqueueErrorSnackbar(`Can't create a new Step: wrong step type ${type}`);
      return;
    }

    setStepConfigurations([...stepConfigurations, draftStep]);
  };

  const handleApplyDraftStepChanges = () => {
    setUnsaved(true);
    setStepConfigurations(stepConfigurations.map((sc) => (sc.draft ? { ...sc, draft: false, id: uuid4() } : sc)));
  };

  const handleCancelDraftChanges = () => {
    setStepConfigurations(stepConfigurations.filter((cs) => !cs.draft));
  };

  const handleDeleteStep = (id: string) => {
    setUnsaved(true);
    if (stepConfigurationsValidState !== null) {
      delete stepConfigurationsValidState[id];
    }
    setStepConfigurationsValidState({ ...stepConfigurationsValidState });
    setStepConfigurations(stepConfigurations.filter((cs) => cs.id != id));
  };

  if (loading) {
    return <Skeleton height="40rem" width="30rem" />;
  }

  if (error) {
    return (
      <>
        <NoContent isSystemData hideHintMessage message={error} />
        <Button onClick={() => fetchTemplate(template.id)}>Try Again</Button>
      </>
    );
  }

  return (
    <Wrapper direction="column" width="30rem" gap={400}>
      <TemplateHeading label={template.label} unsaved={unsaved} />

      <TemplateFieldsForm initialValue={templateFormField!} onChange={handleTemplateFieldsFormChange} />

      <ContentWrapper>
        <TemplateSteps canAdd={!stepConfigurations.some((sc) => sc.draft)} onAddDraft={handleAddDraftStep}>
          <Accordion>
            {stepConfigurations.map((stepConfiguration) => (
              <Panel
                key={stepConfiguration.id}
                title={<StepHeading displayName={stepConfiguration.type} isDraft={!!stepConfiguration.draft} />}
              >
                <ContentWrapper>
                  <TemplateStepForm
                    stepConfiguration={stepConfiguration}
                    onChange={handleStepConfigurationFormChange}
                    onValidChange={handleStepConfigurationFormValidChange}
                    onApplyChanges={handleApplyDraftStepChanges}
                    onCancel={handleCancelDraftChanges}
                    onDelete={handleDeleteStep}
                  />
                </ContentWrapper>
              </Panel>
            ))}
          </Accordion>
        </TemplateSteps>
      </ContentWrapper>

      <Wrapper gap="200" justifyContent="end">
        <Button disabled={!isValid} onClick={action === 'create' ? handleCreate : handleUpdate}>
          {action === 'create' ? 'Create Template' : 'Update Template'}
        </Button>
        <Button kind="secondary" onClick={action === 'create' ? onCancel : () => onDelete(template.id)}>
          {action === 'create' ? 'Cancel Creating' : 'Delete Template'}
        </Button>
      </Wrapper>
    </Wrapper>
  );
}

export type TemplateFieldsForm = Pick<
  PipelineTemplate,
  'mandatory' | 'label' | 'vf_module_id' | 'description' | 'zendesk_url' | 'category'
>;

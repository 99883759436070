import { useState, useEffect } from 'react';
import { useSnackbar } from '@visualfabriq/vf-ui-kit';

import { useInstances } from 'src/components/hooks/useInstances';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { SftpApi } from 'src/api-new/bifrost';
import { SftpInboundUserPubKey } from 'src/dto/sftp';

export type SftpCredentials = {
  id: string;
  importedDate: string;
  inbound: {
    key: string;
    body: string;
  };
  outbound:
    | {
        body: string;
        key: string;
      }
    | undefined;
};

type SftpCredentialsState = {
  credentials: SftpCredentials[];
  loading: boolean;
  errorMessage: string | null;
  handleDelete: (credentials: SftpCredentials[]) => Promise<void>;
  fetchSftpCredentials: () => Promise<void>;
  setErrorMessage: (message: string) => void;
};

const useSftpCredentialsState = (): SftpCredentialsState => {
  const [credentials, setCredentials] = useState<SftpCredentials[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { selectedInstance } = useInstances();
  const sftpApi = useBifrostApi(SftpApi);
  const { enqueueErrorSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedInstance?.id) {
      fetchSftpCredentials();
    }
  }, [selectedInstance?.id]);

  const fetchSftpCredentials = async () => {
    setLoading(true);
    try {
      const [{ data: inboundUser }, { data: outboundUser }] = await Promise.all([
        sftpApi.getInboundAccountForInstance({ instanceId: selectedInstance.id }),
        sftpApi.getOutboundAccountForInstance({ instanceId: selectedInstance.id }),
      ]);

      const outboundUserKeysMapBySshPublicKeyBody = outboundUser.ssh_public_keys.reduce<
        Map<string, SftpInboundUserPubKey>
      >((map, key) => map.set(key.ssh_public_key_body, key), new Map());

      const credentials = inboundUser.ssh_public_keys.map<SftpCredentials>((inboundUserPubKey) => {
        const outboundUserPubKey = outboundUserKeysMapBySshPublicKeyBody.get(inboundUserPubKey.ssh_public_key_body)!;
        const outbound = outboundUserPubKey
          ? { body: outboundUserPubKey.ssh_public_key_body, key: outboundUserPubKey.ssh_public_key_id }
          : undefined;
        return {
          id: inboundUserPubKey.ssh_public_key_id,
          importedDate: inboundUserPubKey.date_imported,
          inbound: {
            body: inboundUserPubKey.ssh_public_key_body,
            key: inboundUserPubKey.ssh_public_key_id,
          },
          outbound,
        };
      });
      setCredentials(credentials);

      setErrorMessage(null);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      setErrorMessage(error.message);
      setCredentials([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete: SftpCredentialsState['handleDelete'] = async (sftpCredentials) => {
    const idsSet = new Set(sftpCredentials.map(({ id }) => id));
    try {
      const responsesData = await Promise.all(
        Array.from(sftpCredentials, async (credential) => {
          const [{ data: inboundResult }, { data: outboundResult }] = await Promise.all([
            sftpApi.deleteInboundSshPublicKey({
              sFTPKeyID: {
                instance_id: selectedInstance.id,
                ssh_public_key_id: credential.inbound.key,
              },
            }),
            credential.outbound?.key
              ? sftpApi.deleteOutboundSshPublicKey({
                  sFTPKeyID: {
                    instance_id: selectedInstance.id,
                    ssh_public_key_id: credential.outbound.key,
                  },
                })
              : Promise.resolve({ data: { removed: true } }),
          ]);
          return [inboundResult, outboundResult];
        }),
      );

      if (responsesData.every(([inboundResult, outboundResult]) => inboundResult.removed && outboundResult.removed)) {
        setCredentials(credentials.filter(({ id }) => !idsSet.has(id)));
        setErrorMessage(null);
      } else {
        const fulfilledIndexes = responsesData.reduce(
          (indexes, [inboundResult, outboundResult], index) =>
            inboundResult.removed && outboundResult.removed ? [...indexes, index] : indexes,
          [],
        );
        const fulfilledIndexesSet = new Set(fulfilledIndexes);
        const fulfilledIdsSet = new Set(
          credentials.filter((_, index) => fulfilledIndexesSet.has(index)).map((inbound) => inbound.id),
        );
        setCredentials(credentials.filter(({ id }) => !fulfilledIdsSet.has(id)));

        throw Error(
          `Failed to delete key(s): ${credentials
            .filter((credential) => !fulfilledIdsSet.has(credential.id))
            .map((credential) => credential.id)}`,
        );
      }
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      setErrorMessage(error.message);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      enqueueErrorSnackbar(error.message);
    }
  };

  return {
    credentials,
    handleDelete,
    loading,
    errorMessage,
    fetchSftpCredentials,
    setErrorMessage,
  };
};

export default useSftpCredentialsState;

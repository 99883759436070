import { Checkbox, FormControl, Input } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type ToDateMethodProps = {
  fields: DataStagingStep.ToDate;
  onUpdate: (fields: DataStagingStep.ToDate) => void;
};

export function ToDateMethod(props: ToDateMethodProps) {
  const { fields, onUpdate } = props;

  return (
    <>
      <FormControl label="Date Format" disabled={false} required>
        <Input
          placeholder="%Y%m%d"
          value={fields.date_format}
          onChange={(event) =>
            onUpdate({
              ...fields,
              date_format: event.target.value,
            })
          }
        />
      </FormControl>
      <Checkbox
        checked={fields.errors_as_none}
        onChange={(event) => {
          onUpdate({
            ...fields,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errors_as_none: !!(event.target as any).checked,
          });
        }}
      >
        Set to null in case of invalid value
      </Checkbox>
    </>
  );
}

import { Input, Select, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { DocumentFilter, DocumentFilterQueryType, DocumentQueryOperator } from 'src/api-new/bifrost';

const operatorItems = [
  { id: DocumentQueryOperator.Eq, label: '=' },
  { id: DocumentQueryOperator.Ne, label: '!=' },
  { id: DocumentQueryOperator.Gt, label: '>' },
  { id: DocumentQueryOperator.Lt, label: '<' },
];
const typeItems = Object.values(DocumentFilterQueryType).map((type) => ({ id: type, label: type }));

type Props = {
  documentFilter: DocumentFilter | undefined;
  onChange: (documentFilter: DocumentFilter | undefined) => void;
  disabled?: boolean;
};
export function Filter(props: Props) {
  const {
    documentFilter = { field: '', query: '', operator: DocumentQueryOperator.Eq, type: 'string' },
    onChange,
    disabled = false,
  } = props;
  const [css, theme] = useStyletron();

  const handleChange = (change: Partial<DocumentFilter>) => {
    onChange({ ...documentFilter, ...change });
  };

  return (
    <Wrapper
      padding={100}
      marginInline={100}
      gap={100}
      className={css({ backgroundColor: disabled ? theme.colors.mono100 : 'initial' })}
    >
      <Input
        disabled={disabled}
        value={documentFilter.field}
        onChange={(event) => handleChange({ field: event.target.value })}
        overrides={inputOverrides}
      />
      <Select
        size="compact"
        disabled={disabled}
        options={operatorItems}
        value={[{ id: documentFilter.operator }]}
        onChange={(event) => {
          if (event.type !== 'select') return;
          //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
          handleChange({ operator: event.value[0].id as DocumentQueryOperator });
        }}
        overrides={selectOverrides}
      />
      <Select
        size="compact"
        disabled={disabled}
        options={typeItems}
        value={[{ id: documentFilter.type }]}
        onChange={(event) => {
          if (event.type !== 'select') return;
          //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
          handleChange({ type: event.value[0].id as DocumentFilterQueryType });
        }}
        overrides={selectOverrides}
      />
      <Input
        disabled={disabled}
        placeholder='Leave empty for ""'
        value={documentFilter.query}
        onChange={(event) => handleChange({ query: event.target.value })}
        overrides={inputOverrides}
      />
    </Wrapper>
  );
}

const selectOverrides = { Root: { style: () => ({ width: '100px' }) } };
const inputOverrides = { Root: { style: () => ({ width: '150px' }) } };

import React, { useEffect, useMemo, useState } from 'react';
import { Button, LabelSmall, Select, styled, Wrapper } from '@visualfabriq/vf-ui-kit';

import { Filter } from './Filter';

import { textBlockOverrides } from 'src/components/styles';
import { DocumentFilter, DocumentFiltersOperator } from 'src/api-new/bifrost';
import { DocumentFiltersOperatorUIOnly } from 'src/domain/insights/types';

const operatorItems = [
  { label: 'AND', id: DocumentFiltersOperator.And },
  { label: 'OR', id: DocumentFiltersOperator.Or },
  { label: 'None', id: DocumentFiltersOperatorUIOnly.None },
];

type Props = {
  documentFilters: DocumentFilter[];
  filtersOperator: DocumentFiltersOperator | DocumentFiltersOperatorUIOnly;
  onChange: (
    documentFilters: DocumentFilter[],
    filtersOperator: DocumentFiltersOperator | DocumentFiltersOperatorUIOnly,
  ) => void;
};
export function DocumentsFilter(props: Props) {
  const { documentFilters, onChange, filtersOperator } = props;
  const [firstFilter, setFirstFilter] = useState<DocumentFilter | undefined>(documentFilters[0]);
  const [secondFilter, setSecondFilter] = useState<DocumentFilter | undefined>(documentFilters[1]);
  const [operator, setOperator] = useState<DocumentFiltersOperator | DocumentFiltersOperatorUIOnly>(filtersOperator);

  useEffect(() => {
    setFirstFilter(documentFilters[0]);
    setSecondFilter(documentFilters[1]);
    setOperator(operator);
  }, [documentFilters]);

  const handleSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const filters: (DocumentFilter | undefined)[] = [firstFilter];
    if (operator !== DocumentFiltersOperatorUIOnly.None) {
      filters.push(secondFilter);
    }

    onChange(
      filters.filter((filter): filter is DocumentFilter => Boolean(filter?.field || filter?.query)),
      operator,
    );
  };

  const handleClear = () => {
    onChange([], DocumentFiltersOperatorUIOnly.None);
    setFirstFilter(documentFilters[0]);
    setSecondFilter(documentFilters[1]);
    setOperator(DocumentFiltersOperatorUIOnly.None);
  };

  //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
  const handleOperatorChange = (event) => {
    if (event.type !== 'select') return;
    setOperator(event.value[0].id as DocumentFiltersOperator);
  };

  const disabled = useMemo(() => operator === DocumentFiltersOperatorUIOnly.None, [operator, filtersOperator]);

  return (
    <FilterForm onSubmit={handleSearch}>
      <LabelSmall marginBottom="0" overrides={textBlockOverrides}>
        Search Key:
      </LabelSmall>
      <Filter documentFilter={firstFilter} onChange={setFirstFilter} />
      <Select
        onChange={handleOperatorChange}
        options={operatorItems}
        value={[{ id: operator }]}
        overrides={selectOverrides}
      />
      <Filter disabled={disabled} documentFilter={secondFilter} onChange={setSecondFilter} />
      <Wrapper gap={200}>
        <Button type="submit">Go</Button>
        <Button type="button" kind="secondary" onClick={handleClear}>
          Clear
        </Button>
      </Wrapper>
    </FilterForm>
  );
}

const FilterForm = styled('form', () => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'auto',
}));
const selectOverrides = { Root: { style: () => ({ width: '85px' }) } };

import { endpointPaths } from '../../endpointPaths';
import { makeApiRequest } from 'src/services/makeApiRequest';
import { handleResponse } from 'src/api/helpers/handleResponse';
import { PipelineStep } from 'src/dto/PipelineStep';

export async function updateStep(step: PipelineStep, getAccessToken: () => Promise<string>): Promise<PipelineStep> {
  const response = await makeApiRequest({
    path: `${endpointPaths.pipelinesSteps}/${step.id}`,
    method: 'PUT',
    body: step,
    getAccessToken,
  });

  return handleResponse(response) as Promise<PipelineStep>;
}

import { useContext, useEffect, useState } from 'react';
import {
  Breadcrumbs,
  BreadcrumbsLoadingBox,
  Button,
  DeleteIcon,
  ListLoadingBox,
  NoContent,
  styled,
  useSnackbar,
} from '@visualfabriq/vf-ui-kit';

import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { ConfirmModal } from 'src/components/organisms/ConfirmModal';
import { PipelineStep } from 'src/dto/PipelineStep';
import { useDefaultFieldsConfiguration } from './components/useDefaultFieldsConfiguration';
import { StepConfigForm } from './StepConfigForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getPipelineStep } from 'src/api/pipelineStep/getPipelineStep';
import { useUser } from 'src/services/useUser';
import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import { usePipelineDrawer } from 'src/containers/Pipelines/PipelineDrawer/usePipelineDrawer';
import { deleteStep } from 'src/api/pipelineStep/deleteStep';
import { updateStep } from 'src/api/pipelineStep/updateStep';
import { pipelinesBreadcrumbsOverrides } from 'src/components/styles';
import { pathConstants } from 'src/routes';
import { captureException } from 'src/services/sentry';
import { getErrorMessage } from 'src/api-new/bifrost/utils';

export function StepConfiguration() {
  const { stepId } = useParams();

  const { openModal, closeModal } = useContext(ModalContext);
  const { getAccessToken } = useUser();
  const { enqueueErrorSnackbar } = useSnackbar();
  const { pipelinesRootPath } = usePipelineDrawer();
  const { selectedPipeline, loading: pipelineLoading } = useContext(PipelinesContext);
  const navigate = useNavigate();

  const [stepLoading, setStepLoading] = useState(true);
  const [changesLoading, setChangesLoading] = useState(false);
  const [step, setStep] = useState<PipelineStep>();
  const { description, name, defaultFieldsConfiguration } = useDefaultFieldsConfiguration({
    step: {
      id: step?.id ?? '',
      name: step?.name ?? '',
      description: step?.description ?? '',
    },
  });

  useEffect(() => {
    async function fetchStep(stepId: string) {
      try {
        setStepLoading(true);
        const step = await getPipelineStep(stepId, getAccessToken);
        setStep(step);
      } catch (error) {
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        enqueueErrorSnackbar(error.message);
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        captureException(error);
      } finally {
        setStepLoading(false);
      }
    }
    if (stepId) {
      fetchStep(stepId);
    }
  }, [stepId]);

  const handleUpdate = async (step: PipelineStep) => {
    try {
      setChangesLoading(true);
      const updatedStep = await updateStep({ ...step, description, name }, getAccessToken);
      setStep(updatedStep);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      enqueueErrorSnackbar(error.message);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      captureException(error);
    } finally {
      setChangesLoading(false);
    }
  };

  const handleDelete = (step: PipelineStep) => () => {
    openModal(
      <ConfirmModal
        onConfirm={async () => {
          try {
            setChangesLoading(true);
            await deleteStep(step.id, getAccessToken);
            navigate(`${pipelinesRootPath}/${selectedPipeline!.id}/${pathConstants.PIPELINES.steps.root}`);
          } catch (error) {
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            enqueueErrorSnackbar(getErrorMessage(error) || error.message);
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            captureException(error);
          } finally {
            setChangesLoading(false);
            closeModal();
          }
        }}
        onCancel={closeModal}
        question={`Delete ${step.name} step?`}
        confirmButtonText="Delete"
      />,
    );
  };

  if (stepLoading || pipelineLoading) {
    return (
      <StepConfigurationWrapper>
        <BreadcrumbsLoadingBox itemsCount={1} />
        <ListLoadingBox />
      </StepConfigurationWrapper>
    );
  }

  if (!step) {
    return (
      <StepConfigurationWrapper>
        <NoContent message={`Can't load Step (id: ${stepId}) data`} />
      </StepConfigurationWrapper>
    );
  }

  return (
    <StepConfigurationWrapper>
      <Breadcrumbs
        overrides={pipelinesBreadcrumbsOverrides}
        steps={[
          { name: selectedPipeline.name, link: `${pipelinesRootPath}/${selectedPipeline.id}/steps` },
          { name: step!.name },
        ]}
      />
      <StepConfigForm
        step={step}
        onUpdate={handleUpdate}
        defaultFieldsConfiguration={defaultFieldsConfiguration}
        loading={changesLoading}
        deleteButton={
          <Button kind="secondary" onClick={handleDelete(step)} disabled={!!stepLoading}>
            <DeleteIcon /> Delete Step
          </Button>
        }
      />
    </StepConfigurationWrapper>
  );
}

const StepConfigurationWrapper = styled('div', ({ $theme }) => ({
  minWidth: '400px',
  margin: `0 ${$theme.sizing.scale200} ${$theme.sizing.scale200}`,
}));

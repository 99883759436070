import { FormControl, Input, Select, Wrapper } from '@visualfabriq/vf-ui-kit';

import { FilterOptions } from 'src/dto/PipelineStep/DataStagingStep';

export type Value = {
  column: string;
  value: string;
  option: FilterOptions;
};

export function TransformationFilter({ value, onChange }: { value: Value; onChange: (value: Value) => void }) {
  const handleChange = (partialValue: Partial<Value>) => {
    onChange({ ...value, ...partialValue });
  };

  return (
    <FormControl label="Filter">
      <Wrapper>
        <Input
          value={value.column}
          placeholder="Column"
          onChange={(event) => handleChange({ column: event.target.value })}
        />
        <Select
          disabled
          value={[{ id: value.option }]}
          options={Object.keys(FilterOptions).map((option) => ({ id: option, label: option }))}
          onChange={(event) => {
            if (event.type !== 'select') return;
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            handleChange({ option: event.value[0].id as FilterOptions });
          }}
        />
        <Input
          value={value.value ? value.value.toString() : ''}
          placeholder="Value"
          onChange={(event) => handleChange({ value: event.target.value })}
        />
      </Wrapper>
    </FormControl>
  );
}

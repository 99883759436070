import {
  Breadcrumbs,
  BreadcrumbsLoadingBox,
  NoContent,
  styled,
  ListLoadingBox,
  FormLoadingBox,
} from '@visualfabriq/vf-ui-kit';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { updateStep } from 'src/api/pipelineStep/updateStep';
import { getPipelineStep } from 'src/api/pipelineStep/getPipelineStep';
import { useUser } from 'src/services/useUser';
import { getConfigurationName } from 'src/domain/pipelines/getConfigurationName';
import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { PipelineStepType } from 'src/dto/PipelineStep';
import { SingleTableConfiguration } from './SingleTable/SingleTableConfiguration';
import { MultiTableConfiguration } from './MultiTable/MulltipleTableConfiguration';
import { pipelinesBreadcrumbsOverrides } from 'src/components/styles';
import { usePipelineDrawer } from '../PipelineDrawer/usePipelineDrawer';
import { pathConstants } from 'src/routes';
import { captureException } from 'src/services/sentry';

export function TableConfiguration() {
  const { tableIndex, stepId } = useParams();
  const { getAccessToken } = useUser();
  const { selectedPipeline, loading: pipelineLoading } = useContext(PipelinesContext);
  const { pipelinesRootPath } = usePipelineDrawer();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tableConfiguration, setTableConfiguration] = useState<DataStagingStep.FileConfiguration>();
  const [fileType, setFileType] = useState<DataStagingStep.FileType>();
  const [step, setStep] = useState<DataStagingStep.DataStagingStep>();

  useEffect(() => {
    async function fetchTableConfiguration(stepId: string, tableIndex: string) {
      try {
        setLoading(true);
        const step = await getPipelineStep(stepId, getAccessToken);

        if (!step) {
          throw new Error(`Couldn't find a step with id "${stepId}"`);
        }

        if (step.type !== PipelineStepType.data_staging) {
          throw new Error(`Table Configuration is supported only for Data Staging Step`);
        }

        const tableConfiguration = step.configuration.files_configuration_model[+tableIndex];

        if (!tableConfiguration) {
          throw new Error(`Couldn't find a table configuration with index "${tableIndex}" for step id "${stepId}"`);
        }

        setStep(step);
        setTableConfiguration(tableConfiguration);
        setFileType(step.configuration.file_type_parameters?.file_type ?? DataStagingStep.FileType.CSV);
      } catch (error) {
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        captureException(error);
        //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    if (stepId && tableIndex) {
      fetchTableConfiguration(stepId, tableIndex);
    }
  }, [tableIndex, stepId]);

  const handleTableDelete = async () => {
    if (!step || !selectedPipeline || !tableIndex) {
      throw new Error('step or selectedPipeline or tableIndex in undefined');
    }

    try {
      setLoading(true);
      await updateStep(
        {
          ...step,
          configuration: {
            ...step.configuration,
            files_configuration_model: step.configuration.files_configuration_model.filter(
              (_, index) => index !== Number(tableIndex),
            ),
          },
        },
        getAccessToken,
      );
      navigate(`${pipelinesRootPath}/${selectedPipeline.id}/${pathConstants.PIPELINES.steps.root}/${stepId}`);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      captureException(error);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTableUpdate = async (
    tableToUpdate: DataStagingStep.SingleTableConfiguration | DataStagingStep.MultiTableConfiguration,
  ) => {
    if (!step || !selectedPipeline || !tableIndex) {
      throw new Error('step or selectedPipeline or tableIndex in undefined');
    }

    try {
      setLoading(true);
      const updated = step.configuration.files_configuration_model.map((table, index) =>
        index == Number(tableIndex) ? tableToUpdate : table,
      );
      const updatedStep = (await updateStep(
        {
          ...step,
          configuration: {
            ...step.configuration,
            files_configuration_model: updated,
          },
        },
        getAccessToken,
      )) as DataStagingStep.DataStagingStep;

      setStep(updatedStep);
      setTableConfiguration(updatedStep.configuration.files_configuration_model[+tableIndex]);
      setFileType(updatedStep.configuration.file_type_parameters?.file_type ?? DataStagingStep.FileType.CSV);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      captureException(error);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    navigate(`${pipelinesRootPath}/${selectedPipeline.id}/${pathConstants.PIPELINES.steps.root}/${stepId}`);
  };

  if (loading || pipelineLoading) {
    return (
      <TableConfigurationWrapper>
        <BreadcrumbsLoadingBox itemsCount={1} />
        <ListLoadingBox itemsCount={3} />
        <FormLoadingBox items={['buttonGroup']} itemsCount={1} />
      </TableConfigurationWrapper>
    );
  }

  if (!tableConfiguration || !fileType) {
    return <NoContent message={error ?? 'Something went wrong'} />;
  }

  return (
    <TableConfigurationWrapper>
      {selectedPipeline && (
        <Breadcrumbs
          overrides={pipelinesBreadcrumbsOverrides}
          steps={[
            { name: selectedPipeline.name, link: `${pipelinesRootPath}/${selectedPipeline.id}/steps` },
            { name: step!.name, link: `${pipelinesRootPath}/${selectedPipeline.id}/steps/${stepId}` },
            { name: getConfigurationName(tableConfiguration) },
          ]}
        />
      )}
      {tableConfiguration.import_type === DataStagingStep.ImportType.Table ? (
        <SingleTableConfiguration
          fileType={fileType}
          table={tableConfiguration}
          onDelete={handleTableDelete}
          onUpdate={handleTableUpdate}
          onCancel={handleCancel}
        />
      ) : (
        <MultiTableConfiguration
          configuration={tableConfiguration}
          onDelete={handleTableDelete}
          onUpdate={handleTableUpdate}
          onCancel={handleCancel}
        />
      )}
    </TableConfigurationWrapper>
  );
}

const TableConfigurationWrapper = styled('div', () => ({
  width: '600px',
}));

import { useState } from 'react';
import {
  Input,
  ModalFooter,
  useSnackbar,
  ModalHeader,
  ModalBody,
  ContentWrapper,
  FormControl,
  Select,
  ModalButton,
  Wrapper,
} from '@visualfabriq/vf-ui-kit';

import { PipelineStepType, PipelineStepConfiguration } from 'src/dto/PipelineStep';
import { defaultConfigurationByStepIdMap } from './defaultConfigs';
import { ImportConfiguration } from './ImportConfiguration';
import { useInstances } from 'src/components/hooks/useInstances';
import { STEP_NAME_BY_ID } from 'src/containers/Pipelines/components/constants';
import { PipelineStepCreate } from 'src/api-new/bifrost';

const STEP_IDS: PipelineStepType[] = [
  PipelineStepType.sftp_inbound_retriever,
  PipelineStepType.azure_inbound_retriever,
  PipelineStepType.azure_outbound_bearer,
  PipelineStepType.sftp_outbound_bearer,
  PipelineStepType.http_outbound_bearer,
  PipelineStepType.data_staging,
  PipelineStepType.data_processing,
  PipelineStepType.files_mover,
];

const canImportConfiguration = [PipelineStepType.data_staging];

type AddStepProps = {
  onConfirm: ({ type, name, configuration, description }: Omit<PipelineStepCreate, 'pipeline_id'>) => void;
  onCancel: () => void;
};

export function AddStepModal(props: AddStepProps) {
  const { onConfirm, onCancel } = props;
  const [selectedType, setSelectedType] = useState(STEP_IDS[0]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [importedConfiguration, setImportedConfiguration] = useState<PipelineStepConfiguration>();
  const { enqueueErrorSnackbar } = useSnackbar();
  const { selectedInstance } = useInstances();

  const handleCreate = () => {
    //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    const configuration = importedConfiguration ?? defaultConfigurationByStepIdMap[selectedType];
    //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    onConfirm({ type: selectedType, name, configuration, description });
  };

  const handleConfigurationImport = (configuration: Omit<PipelineStepConfiguration, 'instance_name'> | null) => {
    setImportedConfiguration({ ...configuration, instance_name: selectedInstance.id });
  };

  return (
    <>
      <ModalHeader>Add Step</ModalHeader>

      <ModalBody>
        <Wrapper direction="column" gap={300}>
          <Input value={name} placeholder="Step name" onChange={(event) => setName(event.target.value)} />
          <Input
            value={description}
            placeholder="Description"
            onChange={(event) => setDescription(event.target.value)}
          />
          <Select
            options={STEP_IDS.map((stepId) => ({ id: stepId, label: STEP_NAME_BY_ID[stepId] }))}
            value={[{ id: selectedType }]}
            onChange={(event) => {
              if (event.type !== 'select') return;
              setSelectedType(event.value[0]!.id as PipelineStepType);
            }}
          />
          {canImportConfiguration.includes(selectedType!) && (
            <ContentWrapper>
              <FormControl label="Import Configuration">
                <ImportConfiguration
                  onChange={handleConfigurationImport}
                  onError={(error) => enqueueErrorSnackbar(`Unable to parse file ${error.message}`)}
                />
              </FormControl>
            </ContentWrapper>
          )}
        </Wrapper>
      </ModalBody>

      <ModalFooter>
        <ModalButton kind="primary" onClick={handleCreate}>
          Create
        </ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
}

import { DependencyList, useEffect, useMemo, useState } from 'react';
import { Checkbox, TableBuilderColumn } from '@visualfabriq/vf-ui-kit';

type TableData = {
  id: string;
};

export function useSelectColumn<TItem extends TableData>(
  props: {
    tableData: TItem[];
    onSelect: (selectedIds: Set<string>) => void;
    disabled?: boolean;
  },
  dependencies: DependencyList,
): [JSX.Element, Selectable<TItem>[]] {
  const { tableData, onSelect, disabled = false } = props;

  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  const selectableItems = useMemo(
    () => tableData.map<Selectable<TItem>>((item) => ({ ...item, selected: selectedIds.has(item.id) })),
    [tableData, selectedIds],
  );

  useEffect(() => {
    const emptySet = new Set<string>();
    onSelect(emptySet);
    setSelectedIds(emptySet);
  }, [...dependencies]);

  const hasAny = Boolean(selectableItems.length);
  const hasAll = hasAny && selectableItems.every((x) => x.selected);
  const hasSome = hasAny && selectableItems.some((x) => x.selected);

  function toggleAll() {
    tableData.forEach((tableItem) => {
      if (hasAll) {
        selectedIds.delete(tableItem.id);
      } else {
        selectedIds.add(tableItem.id);
      }
    });

    setSelectedIds(selectedIds);
    onSelect(selectedIds);
  }

  //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
  function toggle(event) {
    const { name: id, checked } = event.currentTarget;
    if (checked) {
      selectedIds.add(id);
    } else {
      selectedIds.delete(id);
    }

    setSelectedIds(selectedIds);
    onSelect(selectedIds);
  }

  const selectColumn = (
    <TableBuilderColumn
      header={
        <Checkbox disabled={disabled} checked={hasAll} isIndeterminate={!hasAll && hasSome} onChange={toggleAll} />
      }
      overrides={{
        TableHeadCell: { style: { width: '1%' } },
        TableBodyCell: { style: { width: '1%' } },
      }}
    >
      {(row) => (
        <Checkbox
          disabled={disabled}
          name={row.id}
          checked={row.selected}
          onChange={toggle}
          dataTestid="useSelectColumn-Checkbox"
        />
      )}
    </TableBuilderColumn>
  );

  return [selectColumn, selectableItems];
}

export type Selectable<T> = T & { selected: boolean };

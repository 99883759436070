import { useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { Skeleton } from '@visualfabriq/vf-ui-kit';

import { UpdateReplicator } from './UpdateReplicator';
import { EnableReplicator } from './EnableReplicator';
import { OnlyRawMessage } from './OnlyRawMessage';
import { useInstances } from 'src/components/hooks/useInstances';
import { FilesReplicator, FilesReplicatorApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { ErrorMessage } from 'src/components/organisms/ErrorMessage';
import { RAW_INSTANCE_PREFIX } from './constants';
import { captureException } from 'src/services/sentry';

export function Replicator() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [replicator, setReplicator] = useState<FilesReplicator | null>(null);
  const { selectedInstance } = useInstances();
  const filesReplicatorApi = useBifrostApi(FilesReplicatorApi);

  const isRawInstance = useMemo(() => selectedInstance?.name.startsWith(RAW_INSTANCE_PREFIX), [selectedInstance]);

  useEffect(() => {
    if (isRawInstance) {
      getReplicator(selectedInstance.id);
    }
  }, [selectedInstance, isRawInstance]);

  async function getReplicator(instanceId: string) {
    try {
      setIsLoading(true);
      const { data: replicators } = await filesReplicatorApi.getReplicators({ sourceInstanceId: instanceId });
      setReplicator(replicators[0] ?? null);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data.detail ?? 'Somethings went wrong');
        captureException(error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdate(replicatorId: string, targetInstances: string[], isEnabled: boolean) {
    try {
      setIsLoading(true);
      await filesReplicatorApi.updateReplicator({
        replicatorId,
        filesReplicatorUpdate: {
          target_instances: targetInstances,
          enabled: isEnabled,
        },
      });
      await getReplicator(selectedInstance.id);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete(replicatorId: string) {
    try {
      setIsLoading(true);
      await filesReplicatorApi.deleteReplicator({ replicatorId });
      await getReplicator(selectedInstance.id);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCreate() {
    try {
      setIsLoading(true);
      await filesReplicatorApi.createReplicator({
        filesReplicatorCreate: { source_instance_id: selectedInstance.id, enabled: true },
      });
      await getReplicator(selectedInstance.id);
    } catch (error) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleApiError(error: Error) {
    if (error instanceof AxiosError) {
      setError(error.response?.data.detail ?? 'Somethings went wrong');
      captureException(error);
    } else {
      throw error;
    }
  }

  if (isLoading) {
    return <Skeleton height="7rem" width="25rem" />;
  }

  if (error) {
    return (
      <ErrorMessage
        message={error}
        onTryAgain={() => {
          setError(null);
          getReplicator(selectedInstance.id);
        }}
      />
    );
  }

  if (selectedInstance && !isRawInstance) {
    return <OnlyRawMessage />;
  }

  return replicator ? (
    <UpdateReplicator replicator={replicator} onUpdate={handleUpdate} onDelete={handleDelete} />
  ) : (
    <EnableReplicator onCreate={handleCreate} />
  );
}

import { FormControl, Input, Select, Textarea } from '@visualfabriq/vf-ui-kit';
import { useEffect } from 'react';

import { TargetSelectionSelect } from '../TargetCollectionSelect';
import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

const PERSIST_METHODS_TYPES = [
  DataStagingStep.PersistMethod.Append,
  DataStagingStep.PersistMethod.Truncate,
  DataStagingStep.PersistMethod.Upsert,
];

export type FormFields = {
  fnmatchExpression: string;
  lowerLevelKey: string;
  key: string;
  targetCollection: string;
  persistMethod: DataStagingStep.PersistMethod;
  updateKeys: string;
  mandatoryKeys: string;
  fileType: DataStagingStep.FileType | undefined;
  transformations: DataStagingStep.TransformationModel[];
};

export function Form(props: {
  value: FormFields;
  onChange: (value: FormFields) => void;
  onValidChange: (valid: boolean) => void;
}) {
  const { value, onChange, onValidChange } = props;

  const showKeyField =
    value.fileType && [DataStagingStep.FileType.JSON, DataStagingStep.FileType.XML].includes(value.fileType);

  useEffect(() => {
    const valid = Boolean(value.targetCollection?.length && value.fnmatchExpression && value.persistMethod);
    onValidChange(valid);
  }, [value]);

  const handleTargetCollectionChange = (
    targetCollection: string,
    defaults:
      | { updateKey: string; mandatoryKeys: string; transformations: DataStagingStep.TransformationModel[] }
      | undefined,
  ) => {
    onChange({ ...value, targetCollection, ...(defaults ?? {}) });
  };

  const handleChange = (partialValue: Partial<FormFields>) => {
    onChange({ ...value, ...partialValue });
  };

  return (
    <>
      <TargetSelectionSelect value={value.targetCollection} onChange={handleTargetCollectionChange} />

      {showKeyField && (
        <FormControl label="Key" required>
          <Input value={value.key} onChange={(event) => handleChange({ key: event.target.value })} />
        </FormControl>
      )}

      <FormControl label="Fnmatch Expression" required>
        <Input
          value={value.fnmatchExpression}
          onChange={(event) => handleChange({ fnmatchExpression: event.target.value })}
        />
      </FormControl>

      <FormControl label="Lower Level Key" caption="Supported for XML or JSON file types only">
        <Input value={value.lowerLevelKey} onChange={(event) => handleChange({ lowerLevelKey: event.target.value })} />
      </FormControl>

      <FormControl label="Persist Method" required>
        <Select
          options={PERSIST_METHODS_TYPES.map((type) => ({ id: type, label: type }))}
          value={[{ id: value.persistMethod }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
            handleChange({ persistMethod: event.value[0].id as DataStagingStep.PersistMethod });
          }}
        />
      </FormControl>

      <FormControl
        label="Update Keys"
        caption={
          <span>
            Enter a list of keys separated by comma i.e., <code>key1, key2, key3</code>{' '}
          </span>
        }
      >
        <Textarea value={value.updateKeys} onChange={(event) => handleChange({ updateKeys: event.target.value })} />
      </FormControl>

      <FormControl label="Mandatory Keys" caption={<code>key1, key2, key3</code>}>
        <Textarea
          value={value.mandatoryKeys}
          onChange={(event) => handleChange({ mandatoryKeys: event.target.value })}
        />
      </FormControl>
    </>
  );
}

import React from 'react';
import { Button, ButtonGroup, Wrapper, styled } from '@visualfabriq/vf-ui-kit';

import { ValueType } from 'src/components/molecules/KeysValues';

type Props = {
  onChange: (args: { value: { key: string; value: string; type: ValueType }; name: 'key' | 'value' }) => void;
  value: { key: string; value: string; type: ValueType };
  refTo?: string;
  canEditType?: boolean;
};

const typeOptions: ValueType[] = ['text', 'boolean', 'number', 'dict', 'list', 'date'];

export const KeyValueInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    onChange,
    refTo,
    value: { key, value, type },
    canEditType = false,
  } = props;

  const selectedTypeIndex = type ? typeOptions.findIndex((option) => option === type) : -1;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue, name } = event.target;
    switch (name) {
      case 'key':
        onChange({ value: { key: targetValue, value, type }, name: 'key' });
        break;
      case 'value':
        onChange({ value: { key, value: targetValue, type }, name: 'value' });
        break;
      default:
    }
  };

  const handleTypeChange = (_: React.SyntheticEvent<HTMLButtonElement>, index: number) => {
    //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    onChange({ value: { key: key, value, type: typeOptions[index] }, name: 'key' });
  };

  return (
    <Wrapper direction="column">
      <Wrapper>
        <StyledInput ref={refTo === 'key' ? ref : null} name="key" value={key} onChange={handleChange} />
        <StyledInput ref={refTo === 'value' ? ref : null} name="value" value={value} onChange={handleChange} />
      </Wrapper>
      {canEditType && (
        <ButtonGroup
          overrides={{ Root: { style: () => ({ marginLeft: '5px', marginBottom: '15px' }) } }}
          mode="radio"
          onClick={handleTypeChange}
          selected={selectedTypeIndex}
          disabled={false}
        >
          {typeOptions.map((option) => (
            <Button key={option}>{option}</Button>
          ))}
        </ButtonGroup>
      )}
    </Wrapper>
  );
});

const StyledInput = styled('input', () => ({
  width: '50%',
  border: '1px solid #e1e4e8',
  padding: '6px 16px',
  height: '32px',
  background: '#fafafa',
  borderRadius: '4px',
  margin: '5px',

  ':focus': {
    background: '#fff',
    border: ' 2px solid #0065e0',
  },

  "[name='key']": {
    marginRight: '5px',
  },
}));

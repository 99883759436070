import { OnChangeParams, Select } from '@visualfabriq/vf-ui-kit';
import { useAsync } from 'react-use';
import { PipelineTemplateListItem, PipelineTemplatesApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';

export function TemplateSearch({ onSelect }: { onSelect: (template: PipelineTemplateListItem) => void }) {
  const templatesApi = useBifrostApi(PipelineTemplatesApi);

  const templatesList = useAsync(async () => {
    const { data } = await templatesApi.getPipelineTemplatesShort({
      limit: 500,
    });
    return data;
  });

  const handleSearchSelect = (event: OnChangeParams) => {
    if (event.type !== 'select') return;
    //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    const id = event.value[0].id as string;
    const template = (templatesList.value ?? []).find((t) => t.id === id);
    if (template) {
      onSelect(template);
    }
  };

  return (
    <Select
      onChange={handleSearchSelect}
      isLoading={templatesList.loading}
      options={(templatesList.value ?? []).map((t) => ({ id: t.id, label: t.label }))}
      placeholder="Search template by name..."
      maxDropdownHeight="100px"
    />
  );
}

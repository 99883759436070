import { Checkbox, FormControl, Input } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { ChangeEvent, useEffect, useState } from 'react';

type ToBooleanMethodProps = {
  fields: DataStagingStep.ToBoolean;
  onUpdate: (event: { fields: { defaultValue: boolean | null; forceType: boolean }; valid: boolean }) => void;
};

export function ToBooleanMethod(props: ToBooleanMethodProps) {
  const { fields, onUpdate } = props;

  const [defaultValue, setDefaultValue] = useState('');
  const [forceType, setForceType] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isVisited, setIsVisited] = useState(false);
  const shouldShowError = !isValid && isVisited;

  useEffect(() => {
    setDefaultValue(String(fields.default));
    setForceType(fields.force_type);
  }, [fields.force_type, fields.default]);

  const validateBooleanValue = (value: string): value is 'true' | 'false' => {
    return ['true', '1', 'false', '0'].includes(value);
  };

  const parseDefaultValue = (value: 'true' | 'false') => {
    return value === 'true';
  };

  const handleDefaultValueOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    const isValid = validateBooleanValue(value);
    setIsValid(isValid);
    setDefaultValue(value);
    onUpdate({
      valid: isValid,
      fields: {
        forceType: fields.force_type,
        defaultValue: isValid ? parseDefaultValue(value) : null,
      },
    });
  };

  return (
    <>
      <FormControl
        label="Default Value"
        disabled={false}
        required
        error={shouldShowError ? 'Please input a valid default values (true, false)' : false}
      >
        <Input
          error={shouldShowError}
          value={defaultValue}
          onBlur={() => setIsVisited(true)}
          onChange={handleDefaultValueOnChange}
        />
      </FormControl>

      <Checkbox
        checked={forceType}
        onChange={(event) => {
          onUpdate({
            valid: isValid,
            fields: {
              ...fields,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              forceType: !!(event.target as any).checked,
              defaultValue: fields.default,
            },
          });
        }}
      >
        Set default in case of invalid value
      </Checkbox>
    </>
  );
}

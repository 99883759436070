export function moveItem<T>(arr: T[], from: number, to: number) {
  const [min, max] = [from, to].sort();

  const result: T[] = [];
  for (let index = 0; index < arr.length; index++) {
    if (index === to) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      result.push(arr[from]);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    } else if (from > to && index > min && index <= max) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      result.push(arr[index - 1]);
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
    } else if (from < to && index >= min && index < max) {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      result.push(arr[index + 1]);
    } else {
      //@ts-expect-error AUTOMATICALLY GENERATED PLS FIX
      result.push(arr[index]);
    }
  }

  return result;
}
